import { useCallback, useEffect, useMemo, useState } from "react";

import { toast } from "react-toastify";
import { useStore } from "reto";
import { DateTime } from "luxon";

import { queryClient, useMutation, useQuery } from "../remotes";
import ProjectStore from "./project";
import SelectionStore from "./selection";
import useFlowdysseaQueries from "../remotes/flowdyssea";
import { useConfirmModal } from "../components/modal";
import IntlStore from "../intl";
import UserStore from "./user";

export interface Bookmark {
  id: string;
  project: string;
  entity: string;
  topic: string;
  granularity: AdministrativeLevelType;
  identifier: string;
  description: string;
  originsArea: string[];
  originsCountry: string[];
  destinationsArea: string[];
  destinationsCountry: string[];
  createOn: DateTime;
  updateOn: DateTime;
  isShared: boolean;
  version: [string, string];
  origins: string[];
  destinations: string[];
  both: string[];
}

export default function BookmarksStore() {
  const { intl, language } = useStore(IntlStore);
  const user = useStore(UserStore);

  const { bookmarksQ } = useFlowdysseaQueries();
  const { projectId, areasName, versionSelection, dataLevel, setDataLevel, setKeyboardHandlers } =
    useStore(ProjectStore);
  const { spaceSelection, setSpaceSelection } = useStore(SelectionStore);

  const { wait: waitForConfirm } = useConfirmModal({
    confirmText: intl.fluidity.bookmarks.settings.delete.confirm,
    cancelText: intl.fluidity.bookmarks.settings.delete.cancel,
  });

  const { wait: waitForAck } = useConfirmModal({
    confirmText: intl.fluidity.bookmarks.notifications.confirm,
    cancelText: undefined,
  });

  const { data: fetchedBookmarks } = useQuery(
    "bookmarks",
    user !== undefined &&
      projectId !== null && {
        parameters: {
          project: projectId,
        },
      },
    bookmarksQ.fetch
  );

  const { data: fetchedShortcuts } = useQuery(
    "bookmarks_shortcuts",
    user !== undefined &&
      projectId !== null && {
        parameters: {
          user: user.id ? user.id : "",
          project: projectId,
        },
      },
    bookmarksQ.fetchShortcuts
  );

  const bookmarks = useMemo(() => {
    const result = new Map<string, Bookmark>();
    if (fetchedBookmarks && user && areasName && Object.keys(areasName).length > 0) {
      for (const bookmark of fetchedBookmarks) {
        const origins = bookmark.originsArea.map(
          (identifier, index) =>
            (
              Object.values(areasName).find(
                (area) =>
                  area.identifier === identifier && area.country === bookmark.originsCountry[index]
              ) as FlowdysseaAreaNameType
            ).id
        );
        const destinations = bookmark.destinationsArea.map(
          (identifier, index) =>
            (
              Object.values(areasName).find(
                (area) =>
                  area.identifier === identifier &&
                  area.country === bookmark.destinationsCountry[index]
              ) as FlowdysseaAreaNameType
            ).id
        );
        result.set(bookmark.id, {
          ...bookmark,
          isShared: user.id !== bookmark.entity,
          createOn: DateTime.fromISO(bookmark.createOn),
          updateOn: DateTime.fromISO(bookmark.updateOn),
          origins: origins,
          destinations: destinations,
          both: destinations.filter((value) => origins.includes(value)),
        });
      }
    }
    return result;
  }, [fetchedBookmarks, user, areasName]);

  const currentBookmark = useMemo(() => {
    const { origins, destinations } = spaceSelection;

    for (const [bookmarkIndex, bookmark] of bookmarks.entries()) {
      if (
        dataLevel === bookmark.granularity &&
        origins.length === bookmark.origins.length &&
        origins.every((area, areaIndex) => area === bookmark.origins[areaIndex]) &&
        destinations.length === bookmark.destinations.length &&
        destinations.every((area, areaIndex) => area === bookmark.destinations[areaIndex])
      ) {
        return bookmarkIndex;
      }
    }

    return null;
  }, [bookmarks, dataLevel, spaceSelection]);

  const getBookmarkName = useCallback(
    (id: string) => {
      const bookmark = bookmarks.get(id);
      if (!bookmark) return "ERROR";
      return bookmark.identifier !== ""
        ? bookmark.identifier
        : intl.fluidity.bookmarks.default_name({
            date: bookmark.createOn.setLocale(language).toLocaleString(),
          });
    },
    [intl, language, bookmarks]
  );

  const { mutate: createBookmark } = useMutation(bookmarksQ.create, {
    onSuccess: () => {
      queryClient.invalidateQueries("bookmarks");
      toast.success(intl.fluidity.bookmarks.notifications.added);
    },
  });

  const { mutate: updateBookmark } = useMutation(bookmarksQ.update, {
    onSuccess: () => {
      queryClient.invalidateQueries("bookmarks");
      queryClient.invalidateQueries("shared_bookmarks");
    },
  });

  const { mutate: deleteBookmark } = useMutation(bookmarksQ.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries("bookmarks");
      queryClient.invalidateQueries("shared_bookmarks");
      queryClient.invalidateQueries("bookmarks_shortcuts");
    },
  });

  const addBookmark = useCallback(
    async (origins: string[], destinations: string[]) => {
      if (dataLevel && areasName && user && versionSelection && projectId && user.id) {
        if (origins.length === 0 && destinations.length === 0) {
          await waitForAck({
            content: intl.fluidity.bookmarks.notifications.no_selection,
          });
          return;
        }

        if (currentBookmark !== null) {
          await waitForAck({
            content: intl.fluidity.bookmarks.notifications.already_exists({
              name: getBookmarkName(currentBookmark),
            }),
          });
          return;
        }

        const bookmark = {
          granularity: dataLevel,
          identifier: "",
          description: "",
          originsArea: origins.map((origin) => areasName[origin].identifier),
          originsCountry: origins.map((origin) => areasName[origin].country),
          destinationsArea: destinations.map((destination) => areasName[destination].identifier),
          destinationsCountry: destinations.map((destination) => areasName[destination].country),
          version: versionSelection,
        };

        createBookmark({
          parameters: {
            user: user.id,
            project: projectId,
          },
          body: bookmark,
        });
      }
    },
    [
      dataLevel,
      areasName,
      user,
      versionSelection,
      projectId,
      currentBookmark,
      createBookmark,
      waitForAck,
      intl.fluidity.bookmarks.notifications,
      getBookmarkName,
    ]
  );

  const editBookmark = useCallback(
    (bookmark: Bookmark) => {
      projectId &&
        updateBookmark({
          parameters: { project: projectId, bookmark: bookmark.id },
          body: bookmark,
        });
    },
    [updateBookmark, projectId]
  );

  const removeBookmark = useCallback(
    (id: string) => {
      deleteBookmark({
        parameters: { bookmark: id },
      });
    },
    [deleteBookmark]
  );

  const [configuredBookmark, setConfiguredBookmark] = useState<string | null>(null);
  const [detailedBookmark, setDetailedBookmark] = useState<string | null>(null);
  const [unfoldedBookmark, setUnfoldedBookmark] = useState<string | null>(null);

  const bookmarksShortcuts = useMemo(() => {
    const byId = new Map<string, number>();
    const byKey = Array.from({ length: 10 }).fill(null) as Array<string | null>;
    if (bookmarks && fetchedShortcuts) {
      for (const shortcut of fetchedShortcuts.shortcuts) {
        byId.set(shortcut.bookmark, shortcut.key);
        byKey[shortcut.key] = shortcut.bookmark;
      }
    }
    return {
      byId: byId,
      byKey: byKey,
    };
  }, [bookmarks, fetchedShortcuts]);

  const { mutate: updateBookmarkShortcut } = useMutation(bookmarksQ.updateShortcut, {
    onSuccess: () => {
      queryClient.invalidateQueries("bookmarks_shortcuts");
    },
  });

  const loadBookmark = useCallback(
    (id: string | null) => {
      if (id === null) {
        toast.dismiss("bookmark-shortcut-found");
        toast.error(intl.fluidity.bookmarks.notifications.no_bookmark, {
          toastId: "bookmark-shortcut-not-found",
        });
      } else {
        const bookmark = bookmarks.get(id);
        if (bookmark) {
          if (dataLevel !== bookmark.granularity) {
            setDataLevel(bookmark.granularity);
          }
          setSpaceSelection({
            origins: bookmark.origins,
            destinations: bookmark.destinations,
          });
          toast.dismiss("bookmark-shortcut-not-found");
          toast.success(intl.fluidity.bookmarks.notifications.loaded, {
            toastId: "bookmark-shortcut-found",
          });
        }
      }
    },
    [bookmarks, dataLevel, intl, setDataLevel, setSpaceSelection]
  );

  const handleKeyDown = useCallback(
    async (event: KeyboardEvent) => {
      if (event.ctrlKey && event.keyCode === 83) {
        addBookmark(spaceSelection.origins, spaceSelection.destinations);
        event.preventDefault();
      }
      let keyNumber = null;
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        keyNumber = event.keyCode - 48;
      } else if (event.keyCode >= 96 && event.keyCode <= 105) {
        keyNumber = event.keyCode - 96;
      }
      if (event.ctrlKey && keyNumber !== null) {
        if (event.shiftKey) {
          if (currentBookmark !== null) {
            const alreadyUsedBookmark = bookmarksShortcuts.byKey[keyNumber];
            if (
              alreadyUsedBookmark !== null &&
              !(await waitForConfirm({
                content: intl.fluidity.bookmarks.settings.keyboard_override.message({
                  shortcut: keyNumber,
                  bookmark: getBookmarkName(alreadyUsedBookmark),
                }),
              }))
            ) {
              return;
            }
            updateBookmarkShortcut({
              parameters: {
                bookmark: currentBookmark,
              },
              body: {
                key: keyNumber,
              },
            });
            toast.success(
              intl.fluidity.bookmarks.notifications.shortcut_changed({ shortcut: keyNumber })
            );
          }
        } else {
          const bookmark = bookmarksShortcuts.byKey[keyNumber];
          loadBookmark(bookmark);
        }
        event.preventDefault();
      }
    },
    [
      addBookmark,
      bookmarksShortcuts.byKey,
      currentBookmark,
      getBookmarkName,
      intl.fluidity.bookmarks.notifications,
      intl.fluidity.bookmarks.settings.keyboard_override,
      loadBookmark,
      spaceSelection.destinations,
      spaceSelection.origins,
      updateBookmarkShortcut,
      waitForConfirm,
    ]
  );

  useEffect(() => {
    setKeyboardHandlers((draft: Map<string, (event: KeyboardEvent) => void>) => {
      draft.set("bookmarks", handleKeyDown);
    });
    return () => {
      setKeyboardHandlers((draft: Map<string, (event: KeyboardEvent) => void>) => {
        draft.delete("bookmarks");
      });
    };
  }, [setKeyboardHandlers, handleKeyDown]);

  return {
    bookmarks,
    bookmarksShortcuts,
    currentBookmark,
    addBookmark,
    editBookmark,
    removeBookmark,
    updateBookmarkShortcut,
    loadBookmark,
    getBookmarkName,
    configuredBookmark,
    setConfiguredBookmark,
    detailedBookmark,
    setDetailedBookmark,
    unfoldedBookmark,
    setUnfoldedBookmark,
  };
}
