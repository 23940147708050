import wretch from "wretch";
import { useStore } from "reto";

import { describeNavitiaJourneys } from "./journeys";

export function NavitiaStore() {
  const wretcher = wretch("https://api.navitia.io/v1").auth(
    "Basic " + btoa(process.env.REACT_APP_NAVITIA_ACCESS_KEY ?? "")
  );

  return {
    journeysQ: describeNavitiaJourneys(wretcher),
  };
}

export default function useNavitiaQueries() {
  return useStore(NavitiaStore);
}
