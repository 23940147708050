import { useStore } from "reto";

import AuthenticateStore from "../../stores/authenticate";
import { describeFlowdysseaBookmarks } from "./bookmarks";
import { describeFlowdysseaOrganisations } from "./organisations";
import { describeFlowdysseaProjects } from "./projects";
import { describeFlowdysseaProperties } from "./properties";
import { describeFlowdysseaTable } from "./table";
import { describeFlowdysseaUsers } from "./users";

export function FlowdysseaStore() {
  const { flowdyssea } = useStore(AuthenticateStore);

  return {
    bookmarksQ: describeFlowdysseaBookmarks(flowdyssea),
    organisationsQ: describeFlowdysseaOrganisations(flowdyssea),
    projectsQ: describeFlowdysseaProjects(flowdyssea),
    propertiesQ: describeFlowdysseaProperties(flowdyssea),
    usersQ: describeFlowdysseaUsers(flowdyssea),
    tableQ: describeFlowdysseaTable(flowdyssea),
  };
}

export default function useFlowdysseaQueries() {
  return useStore(FlowdysseaStore);
}
