/** Defaults to true unless its value is false or it is a function and it returns false */
export type ModalCallback = () =>
  | boolean
  | null
  | undefined
  | void
  | Promise<boolean | null | undefined | void>;

/**
 * Resolves the given modal callback. Returns true unless it is given the value false or it is given
 * a function which returns false.
 */
export async function callModalCallback(callback: ModalCallback) {
  try {
    const value = await Promise.resolve(callback());
    if (value === false) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
}
