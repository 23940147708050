import {
  ParametersBaseType,
  QueryBaseType,
  BodyBaseType,
  ResponseBaseType,
  result,
  query,
  format,
  RestEndpoint,
  RestEndpointConfig,
} from "./common";

export function describePut<
  TParameters extends ParametersBaseType = undefined,
  TQuery extends QueryBaseType = undefined,
  TBody extends BodyBaseType = undefined,
  TResponse extends ResponseBaseType = undefined
>(config: RestEndpointConfig<TParameters, TQuery, TBody, TResponse, true>) {
  return {
    base: config.base,
    fn: (options: { parameters?: TParameters; query?: TQuery; body?: TBody }) =>
      result(
        query(
          format(config.base, config.url, options && options.parameters),
          options && options.query
        ).put(options && options.body),
        config.response
      ),
  } as RestEndpoint<TParameters, TQuery, TBody, TResponse>;
}
