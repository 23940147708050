import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "reto";
import { enableMapSet } from "immer";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";

import App from "./app";
import "./variables/default-theme";
import ApplicationStore from "./stores/application";
import { queryClient } from "./remotes/query";
import "react-toastify/dist/ReactToastify.css";
import "normalize.css/normalize.css";
import "./assets/css/base.css";

if (process.env.NODE_ENV !== "development") {
  const sentryIgnoreCategories = new Set(["console", "fetch", "ui.click", "ui.input"]);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "fluidity@" + process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_BRANCH,
    beforeBreadcrumb(breadcrumb) {
      return !breadcrumb.category || !sentryIgnoreCategories.has(breadcrumb.category)
        ? breadcrumb
        : null;
    },
  });
}

// Immer options
enableMapSet();

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider of={ApplicationStore} memo>
        <App />
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>,
  document.querySelector("#root")
);
