import { useCallback, useMemo, useRef, useState } from "react";

import { useLocalStorageState } from "ahooks";
import wretch, { Wretcher, ConfiguredMiddleware } from "wretch";
import { toast } from "react-toastify";

import { queryClient } from "../remotes";
import { useToken } from "../hooks/utilities/token";

interface Logs {
  email: string;
  password: string;
}

const storageKey = "auth-tokens-" + process.env.NODE_ENV;
const prefixUrl = process.env.REACT_APP_API_SERVER;

export default function AuthenticateStore() {
  const siteWretch = useMemo(() => wretch(prefixUrl), []);

  const [tokenStorage, setTokenStorage] = useLocalStorageState<{
    access: string;
    refresh: string;
  } | null>(storageKey, { defaultValue: null });

  const accessToken = useToken(tokenStorage ? tokenStorage.access : null);
  const refreshToken = useToken(tokenStorage ? tokenStorage.refresh : null);

  const accessTokenLock = useRef(false);
  const requestQueue = useRef<(() => void)[]>([]);

  const login = useCallback(
    async (logs: Logs) => {
      return siteWretch
        .url("/authenticate")
        .post({ type: "Authenticate", value: logs })
        .json()
        .then((newToken) => {
          accessToken.setEncoded(newToken.value.access.value);
          refreshToken.setEncoded(newToken.value.refresh.value);
          const encodedAccessToken = accessToken.getEncoded();
          const encodedRefreshToken = refreshToken.getEncoded();
          if (encodedAccessToken && encodedRefreshToken) {
            setTokenStorage({
              access: encodedAccessToken,
              refresh: encodedRefreshToken,
            });
          }
          setUserRandomKey(Math.random());
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [accessToken, refreshToken, setTokenStorage, siteWretch]
  );

  const logout = useCallback(() => {
    if (accessToken.getEncoded() || refreshToken.getEncoded()) {
      accessToken.setEncoded(null);
      refreshToken.setEncoded(null);
      setTokenStorage(null);
      queryClient.invalidateQueries();
    }
  }, [accessToken, refreshToken, setTokenStorage]);

  const refresh = useCallback(async () => {
    if (refreshToken.isValid() && !accessToken.isValid()) {
      if (accessTokenLock.current) {
        await new Promise<void>((resolve) => {
          requestQueue.current.push(() => {
            resolve();
          });
        });
      } else {
        accessTokenLock.current = true;
        let tokenChanged = false;
        while (!tokenChanged) {
          const refreshBearer = refreshToken.getBearer();
          await siteWretch
            .url("/authenticate")
            .headers({ "X-Refresh-Token": refreshBearer !== null ? refreshBearer : "" })
            .delete()
            .json()
            .then((newAccess) => {
              accessToken.setEncoded(newAccess.value);
              const encodedAccessToken = accessToken.getEncoded();
              const encodedRefreshToken = refreshToken.getEncoded();
              if (encodedAccessToken && encodedRefreshToken) {
                setTokenStorage({
                  access: encodedAccessToken,
                  refresh: encodedRefreshToken,
                });
              }
              tokenChanged = true;
              return true;
            })
            .catch(() => {
              toast.error("Erreur réseau. Rétablissement de la connexion...", {
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                toastId: "access-token-error",
              });
            });
          if (!refreshToken.isValid() && !accessToken.isValid()) {
            toast.dismiss("access-token-error");
            logout();
            throw undefined;
          }
          if (!tokenChanged) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        }
        while (requestQueue.current.length > 0) {
          const request = requestQueue.current.pop();
          if (request !== undefined) {
            request();
          }
        }
        toast.dismiss("access-token-error");
        accessTokenLock.current = false;
      }
    }
    if (!refreshToken.isValid() && !accessToken.isValid()) {
      logout();
      throw undefined;
    }
  }, [accessToken, logout, refreshToken, setTokenStorage, siteWretch]);

  const accessTokenMiddleware = useCallback<ConfiguredMiddleware>(
    (next) => async (url, options) => {
      await refresh();
      if (!refreshToken.isValid() && !accessToken.isValid()) {
        logout();
        throw undefined;
      }
      const accessBearer = accessToken.getBearer();
      options.headers = {
        ...options.headers,
        Authorization: accessBearer !== null ? accessBearer : "",
      };
      return next(url, options);
    },
    [accessToken, refresh, refreshToken, logout]
  );

  const flowdyssea = useMemo(
    (): Wretcher => siteWretch.middlewares([accessTokenMiddleware]),
    [accessTokenMiddleware, siteWretch]
  );

  const isAccessible = useCallback(() => {
    if (refreshToken.isValid() && !accessToken.isValid()) {
      refresh();
    }
    return accessToken.isValid();
  }, [accessToken, refresh, refreshToken]);

  const [userRandomKey, setUserRandomKey] = useState(0);

  return {
    flowdyssea,
    siteWretch,
    login,
    logout,
    isAccessible,
    userRandomKey,
    setUserRandomKey,
    isRefreshable: refreshToken.isValid,
    isAdministrator: accessToken.isAdmin,
  };
}
