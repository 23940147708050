import { GeoJSON } from "geojson";

import { Wretcher } from "wretch";
import { parseISO } from "date-fns";

import { describeGet, transformInput, transformOutput, type } from "../descriptors";
import { PublicJourney, PublicCommonSection, PublicStreetSection } from "./types";

function pointToId(point: [number, number]): string {
  return point[0] + ";" + point[1];
}

export const describeNavitiaJourneys = (wretcher: Wretcher) => {
  const get = transformOutput(
    transformInput(
      describeGet({
        base: wretcher,
        url: "/journeys",
        query: type<{
          from: string;
          to: string;
          departure: string;
          max_nb_journeys: number;
        }>(),
        response: type<{ [key: string]: unknown }>(), // to-do: change any to data received
        handleError: (error) => {
          if (error.status === 404) {
            return null;
          } else {
            throw error;
          }
        },
      }),
      (arguments_: { from: [number, number]; to: [number, number]; departure: string }) => ({
        query: {
          from: pointToId(arguments_.from),
          to: pointToId(arguments_.to),
          departure: arguments_.departure,
          max_nb_journeys: 2,
        },
      })
    ),
    async (response) => {
      const data = await response;

      if (!data) {
        return null;
      }
      if (!data.journeys) {
        return null;
      }
      return (
        data.journeys as {
          arrival_date_time: string;
          departure_date_time: string;
          sections: {
            type: string;
            from: { name: string };
            to: { name: string };
            mode: string;
            geojson: GeoJSON & { properties: string[] };
            arrival_date_time: string;
            departure_date_time: string;
            display_informations: {
              color: string;
              direction: string;
              label: string;
              name: string;
              network: string;
              physical_mode: string;
              commercial_mode: string;
            };
          }[];
        }[]
      ).map((journey) => {
        const newJourney: PublicJourney = {
          properties: {
            arrival: parseISO(journey.arrival_date_time),
            departure: parseISO(journey.departure_date_time),
            from: journey.sections[0].from.name,
            length: 0,
            to: journey.sections[journey.sections.length - 1].to.name,
          },
          sections: [],
        };
        for (const section of journey.sections) {
          switch (section.type) {
            case "public_transport":
              const color = section.display_informations.color;
              newJourney.sections.push({
                type: "Feature",
                geometry: section.geojson,
                properties: {
                  type: "public",
                  arrival: parseISO(section.arrival_date_time),
                  departure: parseISO(section.departure_date_time),
                  section: "common",
                  journey: newJourney,
                  length: section.geojson.properties[0].length,
                  lineColor: "#" + (color && color !== "" ? color : "000000"),
                  lineDirection: section.display_informations.direction,
                  lineLabel: section.display_informations.label,
                  lineName: section.display_informations.name,
                  lineNetwork: section.display_informations.network,
                  lineType: section.display_informations.physical_mode,
                  lineTypeShort: section.display_informations.commercial_mode,
                },
              } as PublicCommonSection);
              newJourney.properties.length += section.geojson.properties[0].length;
              break;
            case "street_network":
            case "crow_fly":
              newJourney.sections.push({
                type: "Feature",
                geometry: section.geojson,
                properties: {
                  type: "public",
                  arrival: parseISO(section.arrival_date_time),
                  departure: parseISO(section.departure_date_time),
                  section: "street",
                  journey: newJourney,
                  length: section.geojson.properties[0].length,
                  mode: section.mode,
                  isCrowFly: true,
                },
              } as PublicStreetSection);
              newJourney.properties.length += section.geojson.properties[0].length;
              break;
            case "transfer":
              newJourney.sections.push({
                type: "Feature",
                geometry: section.geojson,
                properties: {
                  type: "public",
                  arrival: parseISO(section.arrival_date_time),
                  departure: parseISO(section.departure_date_time),
                  section: "street",
                  journey: newJourney,
                  length: section.geojson.properties[0].length,
                  mode: "walking",
                  isCrowFly: false,
                },
              } as PublicStreetSection);
              newJourney.properties.length += section.geojson.properties[0].length;
              break;
          }
        }
        return newJourney;
      });
    }
  );

  return {
    get,
  };
};
