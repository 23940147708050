import { makeDefaultFragment, required } from "../utils";

export default makeDefaultFragment({
    admin_button: "Administrate",
    organisations_button: "Organisation",
    organisation: {
        add_button: "Add",
        add_button_placeholder: "Create a new account",
        add_cancel: "Cancel",
        add_confirm: "Create",
        add_title: "Create a new account",
        delete_button: "Delete",
        delete_confirmation: [
            "Are you sure you want to delete {name}'s account? This cannot be undone.",
            {
                username: required<string>(),
            },
        ],
        edit_button: "Edit",
        edit_cancel: "Cancel",
        edit_confirm: "Confirm",
        edit_title: "Edit an existing account",
        form_firstname: "First name",
        form_firstname_error: "First name cannot be empty",
        form_lastname: "Last name",
        form_lastname_error: "Last name cannot be empty",
        form_email: "Email",
        form_email_error_invalid: "Enter a valid email address",
        form_email_error_exists: "Email address already used by another account",
        form_role: "Role",
        form_role_user: "User",
        form_role_manager: "Manager",
        search_placeholder: "Search",
    },
    projects: {
        add_members: {
            title: "Add members to project",
            placeholder: "Select an user to add...",
            cancel: "Cancel",
            confirm: "Add",
            cancel_text: "Are you sure you do not want to add the selected members?",
            cancel_no: "No",
            cancel_yes: "Yes",
        },
        back_to_list: "Projects list",
        delete_member: {
            message: [
                "Are you sure you want to remove {username} from this project?",
                {
                    username: required<string>(),
                },
            ],
            cancel: "Cancel",
            confirm: "Delete",
        },
        hello: ["Welcome, {name}", { name: required<string>() }],
        member_list: "Project members",
        no_project_message: `You do not have any assigned project. You can contact your manager
      for more informations.`,
        no_project_message_manager: `You do not have any assigned project. You can view all
      your organisation projects if you wish to assign yourself or someone else to a project.`,
        no_all_projects: `Your organisation does not have any project at all. You can contact
      Entropy's support at support@entropy.sc for more informations.`,
        no_project_title: "No project",
        open_project: "Open project",
        view_all_projects: "View all projects",
        view_my_projects: "View only my projects",
        title: "My projects",
        title_all: "My organisation's projects",
    },
    logout_button: "Log out",
});
