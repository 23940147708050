import { Wretcher } from "wretch";

import { type, describeDelete, describeGet, describePost, describePut } from "../descriptors";

export type User = {
  id?: string;
  email: string;
  firstname: string;
  lastname: string;
  administrator?: boolean;
  organisation?: string;
  role?: string;
};

export const describeFlowdysseaUsers = (wretcher: Wretcher) => ({
  ping: describeGet({
    base: wretcher,
    url: "/authenticate",
    response: type<FlowdysseaUserUniqueIdentifierType>(),
  }),

  create: describePost({
    base: wretcher,
    url: "/organisations/<organisation>/users/",
    parameters: type<{ organisation: string }>(),
    body: type<User & { password?: string }>(),
  }),

  fetch: describeGet({
    base: wretcher,
    url: "/users/",
    response: type<FlowdysseaListType<FlowdysseaUserType>>(),
  }),

  fetchByOrganisation: describeGet({
    base: wretcher,
    url: "/organisations/<organisation>/users/",
    parameters: type<{ organisation: string }>(),
    response: type<FlowdysseaListType<FlowdysseaUserType>>(),
  }),

  fetchByProject: describeGet({
    base: wretcher,
    url: "/projects/<project>/users/",
    parameters: type<{ project: string }>(),
    response: type<FlowdysseaListType<FlowdysseaUserType>>(),
  }),

  get: describeGet({
    base: wretcher,
    url: "/users/<user>",
    parameters: type<{ user: string }>(),
    response: type<FlowdysseaUserType>(),
  }),

  update: describePut({
    base: wretcher,
    url: "/users/<user>",
    parameters: type<{ user: string }>(),
    body: type<User>(),
  }),

  delete: describeDelete({
    base: wretcher,
    url: "/users/<user>",
    parameters: type<{ user: string }>(),
  }),
});
