import { useMemo } from "react";

import { useStore } from "reto";
import { enGB, fr } from "date-fns/locale";

import lang_enGB from "./en-gb";
import lang_frFR from "./fr-fr";
import ApplicationStore from "../stores/application";
import { makeTranslator } from "./utils";

export default function IntlStore() {
  const { language } = useStore(ApplicationStore);

  const intl = useMemo(() => {
    switch (language) {
      case "fr-FR":
        return makeTranslator(lang_enGB, lang_frFR);
      default:
        return makeTranslator(lang_enGB);
    }
  }, [language]);

  const fnsLocale = useMemo(() => {
    switch (language) {
      case "fr-FR":
        return fr;
      default:
        return enGB;
    }
  }, [language]);

  return { intl, language, fnsLocale };
}
