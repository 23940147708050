import {
  ParametersBaseType,
  QueryBaseType,
  BodyBaseType,
  ResponseBaseType,
  result,
  query,
  format,
  RestEndpoint,
  RestEndpointConfig,
} from "./common";

export function describePost<
  TParameters extends ParametersBaseType = undefined,
  TQuery extends QueryBaseType = undefined,
  TBody extends BodyBaseType = undefined,
  TResponse extends ResponseBaseType = undefined
>(
  config: RestEndpointConfig<TParameters, TQuery, TBody, TResponse, true> & {
    arrayBuffer?: boolean;
  }
) {
  return {
    base: config.base,
    fn: (options: { parameters?: TParameters; query?: TQuery; body?: TBody }) =>
      result(
        query(
          format(config.base, config.url, options && options.parameters),
          options && options.query
        ).post(options && options.body),
        config.response,
        config.arrayBuffer
      ),
  } as RestEndpoint<TParameters, TQuery, TBody, TResponse>;
}
