import other from "../en-gb/other";
import { makeFullFragment } from "../utils";

const error = makeFullFragment<typeof other.error>({
    assistance: `Si vous avez besoin d'aide, vous pouvez contacter le support
    d'Entropy à {link}.`,
    back_to_page_button: "Rafraîchir la page",
    back_to_previous_page_button: "Retourner à la page précedente",
    cancel_modify_button: "Annuler",
    cannot_modify: `Vous ne pouvez pas modifier votre rapport plus
    de 5 minutes après l'envoi initial.`,
    cannot_send: `Vous ne pouvez pas envoyer de rapport plus de 30 minutes
    après l'incident.`,
    comment_label: "Que s'est-il passé ?",
    comment_placeholder: "J'ai cliqué sur 'X' puis sur 'Confirmer'.",
    email_label: "Adresse email (optionel)",
    description: `L'équipe de développement d'Entropy a été prévenue automatiquement.
    Si vous souhaitez aider, vous pouvez décrire ce qu'il s'est passé dans le
    formulaire ci-dessous.`,
    modify_button: "Modifier le rapport",
    name_label: "Nom et prénom (optionel)",
    title: "L'application a planté à cause d'une erreur inconnue",
    sent_text: `Votre rapport à été envoyé à notre équipe de développement. Nous
    allons faire notre possible pour corriger ce problème dans les plus brefs délais.`,
    submit_button: "Envoyer le rapport",
    submit_modified_button: "Envoyer le rapport modifié",
});

const not_found = makeFullFragment<typeof other.not_found>({
    dashboard: "Retourner à l'accueil",
    message:
        "Impossible de trouver l'URL fournie. Si vous pensez que c'est une erreur, merci de {link}.",
    message_link: "contacter le support d'Entropy",
    title: "Erreur 404: page introuvable",
});

export default {
    error,
    not_found,
};
