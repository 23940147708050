import { useCallback, useEffect, useMemo, useState } from "react";

import geobuf from "geobuf";
import Pbf from "pbf";

import { useQuery } from "../remotes";
import useImmerState from "../hooks/utilities/immer-state";
import useFlowdysseaQueries from "../remotes/flowdyssea";

export default function ProjectStore() {
  const [projectId, setProjectId] = useState<string | null>(null);
  const [versionProjectId, setVersionProjectId] = useState<string | null>(null);
  const [level, setLevel] = useState<AdministrativeLevelType>("town");
  const [dataLevel, setDataLevel] = useState<AdministrativeLevelType | null>("hybrid");
  const [rawVersionSelection, setVersionSelection] = useState<[string, string] | null>(null);
  const [overlayLow, setOverlayLow] = useState<OverlayGranularityGeosjon | null>(null);
  const [overlayMedium, setOverlayMedium] = useState<OverlayGranularityGeosjon | null>(null);
  const [areasCentroid, setAreasCentroid] = useState<{ [keyof: string]: [number, number] } | null>(
    null
  );

  const { projectsQ } = useFlowdysseaQueries();

  const [keyboardHandlers, setKeyboardHandlers] = useImmerState<
    Map<string, (event: KeyboardEvent) => void>
  >(new Map());

  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent) => {
      for (const entry of keyboardHandlers) {
        entry[1](event);
      }
    },
    [keyboardHandlers]
  );

  const { data: indicators } = useQuery(
    "project_indicators",
    projectId !== null && { parameters: { project: projectId } },
    projectsQ.getIndicators,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: versions } = useQuery(
    "project_versions",
    projectId !== null && { parameters: { project: projectId } },
    projectsQ.getVersions,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (versions !== undefined && versions.length > 0 && versions[0].project === projectId) {
      setVersionSelection(versions[0].version);
      setVersionProjectId(projectId);
    }
  }, [versions, rawVersionSelection, projectId, versionProjectId]);

  const versionSelection = useMemo(() => {
    return projectId !== versionProjectId || projectId === null ? null : rawVersionSelection;
  }, [projectId, versionProjectId, rawVersionSelection]);

  const { data: times } = useQuery(
    "project_times",
    projectId !== null &&
      indicators !== undefined &&
      indicators.length > 0 &&
      versionSelection !== null && {
        parameters: {
          timeslots: indicators[0].timeslots,
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getTimes,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: areasName } = useQuery(
    "project_areas_name",
    projectId !== null &&
      versions !== undefined &&
      versions.length > 0 &&
      versions[0].project === projectId &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getAreasName,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: areasStatistics } = useQuery(
    "project_areas_statistics",
    projectId !== null &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getStatistics,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: geobufDistrictLow } = useQuery(
    "project_geobuf_district_low",
    projectId !== null &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          granularity: "district",
          resolution: "low",
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getGeobuf,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: geobufTownLow } = useQuery(
    "project_geobuf_town_low",
    projectId !== null &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          granularity: "town",
          resolution: "low",
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getGeobuf,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: geobufIntercommunalLow } = useQuery(
    "project_geobuf_intercommunal_low",
    projectId !== null &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          granularity: "intercommunal",
          resolution: "low",
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getGeobuf,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: geobufDistrictMedium } = useQuery(
    "project_geobuf_district_medium",
    projectId !== null &&
      overlayLow !== null &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          granularity: "district",
          resolution: "medium",
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getGeobuf,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: geobufTownMedium } = useQuery(
    "project_geobuf_town_medium",
    projectId !== null &&
      overlayLow !== null &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          granularity: "town",
          resolution: "medium",
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getGeobuf,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: geobufIntercommunalMedium } = useQuery(
    "project_geobuf_intercommunal_medium",
    projectId !== null &&
      overlayLow !== null &&
      versionSelection !== null && {
        parameters: {
          project: projectId,
          granularity: "intercommunal",
          resolution: "medium",
          versionBegin: versionSelection[0],
          versionEnd: versionSelection[1],
        },
      },
    projectsQ.getGeobuf,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      geobufDistrictLow !== undefined &&
      geobufTownLow !== undefined &&
      geobufIntercommunalLow !== undefined
    ) {
      const districtGeojson = geobuf.decode(
        new Pbf(geobufDistrictLow)
      ) as unknown as OverlayFeatureCollectionType;
      const townGeojson = geobuf.decode(
        new Pbf(geobufTownLow)
      ) as unknown as OverlayFeatureCollectionType;
      const intercommunalGeojson = geobuf.decode(
        new Pbf(geobufIntercommunalLow)
      ) as unknown as OverlayFeatureCollectionType;

      const areasCentroid: { [keyof: string]: [number, number] } = {};

      for (const granularityGeojson of [districtGeojson, townGeojson, intercommunalGeojson]) {
        for (const feature of granularityGeojson.features) {
          if (feature.properties !== null) {
            areasCentroid[feature.properties.id] = [
              feature.properties.centroidX,
              feature.properties.centroidY,
            ];
          }
        }
      }

      setAreasCentroid(areasCentroid);
      setOverlayLow({
        district: districtGeojson,
        town: townGeojson,
        hybrid: {
          type: "FeatureCollection",
          features: [
            ...districtGeojson.features,
            ...townGeojson.features.filter(
              (feature) => feature.properties && feature.properties.base === "t"
            ),
          ],
        },
        intercommunal: intercommunalGeojson,
      });
    } else {
      setAreasCentroid(null);
      setOverlayLow(null);
    }
  }, [geobufDistrictLow, geobufTownLow, geobufIntercommunalLow]);

  useEffect(() => {
    if (
      geobufDistrictMedium !== undefined &&
      geobufTownMedium !== undefined &&
      geobufIntercommunalMedium !== undefined
    ) {
      const districtGeojson = geobuf.decode(
        new Pbf(geobufDistrictMedium)
      ) as unknown as OverlayFeatureCollectionType;
      const townGeojson = geobuf.decode(
        new Pbf(geobufTownMedium)
      ) as unknown as OverlayFeatureCollectionType;

      setOverlayMedium({
        district: districtGeojson,
        town: townGeojson,
        hybrid: {
          type: "FeatureCollection",
          features: [
            ...districtGeojson.features,
            ...townGeojson.features.filter(
              (feature) => feature.properties && feature.properties.base === "t"
            ),
          ],
        },
        intercommunal: geobuf.decode(
          new Pbf(geobufIntercommunalMedium)
        ) as unknown as OverlayFeatureCollectionType,
      });
    } else {
      setOverlayMedium(null);
    }
  }, [geobufDistrictMedium, geobufTownMedium, geobufIntercommunalMedium]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent);
    };
  }, [handleKeyboardEvent]);

  const timeslotsIndex = useMemo(() => {
    if (times !== undefined) {
      const memoize: { [keyof: string]: number } = {};
      for (const time of times) {
        memoize[time.id] = time.index;
      }
      return memoize;
    } else {
      return undefined;
    }
  }, [times]);

  const timeslotsId = useMemo(() => {
    if (times !== undefined) {
      const memoize: { [keyof: number]: string } = {};
      for (const time of times) {
        memoize[time.index] = time.id;
      }
      return memoize;
    } else {
      return undefined;
    }
  }, [times]);

  const mainIndicator = useMemo(() => {
    return indicators !== undefined ? indicators.find((indicator) => indicator.main) : undefined;
  }, [indicators]);

  const areasInclude = useMemo(() => {
    const memoizeParent: { [keyof: string]: string[] } = {};
    const memoize: { [keyof: string]: string[] } = {};
    if (areasName !== undefined) {
      for (const area of Object.values(areasName)) {
        if (area.parent !== null) {
          if (!(area.parent in memoizeParent)) {
            memoizeParent[area.parent] = [area.id];
          } else {
            memoizeParent[area.parent].push(area.id);
          }
          if (area.base) {
            memoizeParent[area.id] = [area.id];
          }
        }
      }
      for (const [id, include] of Object.entries(memoizeParent)) {
        for (const childId of include) {
          if (id in memoize) {
            if (areasName[childId].base) {
              memoize[id].push(childId);
            } else if (childId in memoizeParent) {
              memoize[id] = [...memoize[id], ...memoizeParent[childId]];
            }
          } else {
            if (areasName[childId].base) {
              memoize[id] = [childId];
            } else if (childId in memoizeParent) {
              memoize[id] = [...memoizeParent[childId]];
            }
          }
        }
      }
    } else {
      return null;
    }
    return memoize;
  }, [areasName]);

  return {
    overlayLow,
    areasCentroid,
    areasStatistics,
    overlayMedium,
    projectId,
    setProjectId,
    level,
    setLevel,
    dataLevel,
    setDataLevel,
    mainIndicator,
    keyboardHandlers,
    setKeyboardHandlers,
    versionSelection,
    setVersionSelection,
    versions,
    areasName,
    indicators,
    areasInclude,
    timeslotsIndex,
    timeslotsId,
  };
}
