import { Wretcher } from "wretch";

import { type, describePost } from "../descriptors";

export type Direction = "origin" | "destination";

// TO-DO: clean descriptors by extracting all that logic out of that file

export const describeFlowdysseaProperties = (wretcher: Wretcher) => ({
  getIndicator: describePost({
    base: wretcher,
    url: "/projects/<project>/resume/numerics/directions",
    parameters: type<{ project: string }>(),
    body: type<{
      indicator: string;
      origins: AreasType;
      destinations: AreasType;
      version: [string, string];
    }>(),
    response: type<number[][]>(),
  }),

  getIndicatorGes: describePost({
    base: wretcher,
    url: "/projects/<project>/resume/numerics/directions/ges",
    parameters: type<{ project: string }>(),
    body: type<{
      indicator: string;
      origins: AreasType;
      destinations: AreasType;
      version: [string, string];
    }>(),
    response: type<number[][]>(),
  }),

  getLinks: describePost({
    base: wretcher,
    url: "/projects/<project>/resume/links",
    parameters: type<{ project: string }>(),
    body: type<{
      indicator: string;
      categories: number[];
      origins: AreasType;
      destinations: AreasType;
    }>(),
    response: type<ArrayBuffer>(),
    arrayBuffer: true,
  }),

  getLinksCategories: describePost({
    base: wretcher,
    url: "/projects/<project>/resume/links/categories",
    parameters: type<{ project: string }>(),
    body: type<{
      indicator: string;
      categories: number[];
      origins: AreasType;
      destinations: AreasType;
    }>(),
    response: type<{ [keyof: string]: number[] }>(),
  }),

  getLinksNumerics: describePost({
    base: wretcher,
    url: "/projects/<project>/resume/links/numerics",
    parameters: type<{ project: string }>(),
    body: type<{
      indicator: string;
      times: string[];
      categories: number[];
      origins: AreasType;
      destinations: AreasType;
    }>(),
    response: type<{ [keyof: string]: { [keyof: string]: number } }>(),
  }),
  getArea: describePost({
    base: wretcher,
    url: "/projects/<project>/resume/areas",
    parameters: type<{ project: string }>(),
    body: type<{
      indicator: string;
      times: string[];
      categories: number[];
      origins: AreasType;
      destinations: AreasType;
      direction: Direction;
    }>(),
    response: type<{ [keyof: string]: number }>(),
  }),

  getFlow: describePost({
    base: wretcher,
    url: "/projects/<project>/resume/numerics/directions/flow",
    parameters: type<{ project: string }>(),
    body: type<{
      indicator: string;
      times: string[];
      categories: number[];
      origins: AreasType;
      destinations: AreasType;
    }>(),
    response: type<{
      [key: string]: {
        [key: string]: number;
      };
    }>(),
  }),

  getTableCount: describePost({
    base: wretcher,
    url: "/projects/<project>/tables/count",
    parameters: type<{ project: string }>(),
    body: type<{
      origins: string[];
      destinations: string[];
      times: string[];
      granularity: string;
      version: [string, string];
      unit: "volume" | "percentage";
      globalFilter: null | string;
      filters: { key: string; type: string; values: number[] }[];
    }>(),
    response: type<{ value: number }>(),
  }),

  getTableRows: describePost({
    base: wretcher,
    url: "/projects/<project>/tables/rows",
    parameters: type<{ project: string }>(),
    body: type<{
      origins: string[];
      destinations: string[];
      times: string[];
      granularity: string;
      version: [string, string];
      pageSize: number;
      pageIndex: number;
      unit: "volume" | "percentage";
      globalFilter: null | string;
      filters: { key: string; type: string; values: number[] }[];
      sortBy: { id: string; desc: boolean }[];
    }>(),
    response: type<
      {
        [key: string]: string | number;
      }[]
    >(),
  }),

  getAreaIndicators: describePost({
    base: wretcher,
    url: "/properties/areas",
    body: type<{
      projectId: string;
      areas: string[];
    }>(),
    response: type<{ [key: string]: number }>(),
  }),
});
