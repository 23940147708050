import { useState, useEffect, useMemo } from "react";

import { useStore } from "reto";
import { toast } from "react-toastify";
import { startOfISOWeek, addSeconds } from "date-fns";
import { useInterval, useKeyPress, useToggle } from "ahooks";

import { getRanges, translation } from "../utilities/time";
import { useQuery } from "../remotes";
import ProjectStore from "./project";
import IntlStore from "../intl";
import useImmerState from "../hooks/utilities/immer-state";
import useNavitiaQueries from "../remotes/navitia";
import useFlowdysseaQueries from "../remotes/flowdyssea";
import useUndo from "../hooks/utilities/undo";

const secondsInDay = 60 * 60 * 24;
const defaultMapMode = "choropleth";

export default function SelectionStore() {
  const {
    dataLevel,
    areasName,
    areasCentroid,
    versionSelection,
    timeslotsId,
    indicators,
    mainIndicator,
    projectId,
  } = useStore(ProjectStore);
  const { intl } = useStore(IntlStore);
  const { journeysQ } = useNavitiaQueries();
  const { propertiesQ } = useFlowdysseaQueries();

  const [timeSelection, setTimeSelection] = useState<SelectionTimeType>([]);
  const [timeSpeed, setTimeSpeed] = useState<number>(2000);

  const [timePlaying, { toggle: toggleTimePlaying }] = useToggle(false);

  const ranges = useMemo(() => getRanges(timeSelection), [timeSelection]);

  const setAllTime = () => {
    setTimeSelection(Array.from({ length: timeSelection.length }, () => true));
  };

  const setPreviousTime = () => {
    const ranges = getRanges(timeSelection);
    if (ranges.length === 1) {
      const steps = (timeSelection.length + ranges[0][1] - ranges[0][0] + 1) % timeSelection.length;
      setTimeSelection(translation(timeSelection, steps));
    }
  };

  const setNextTime = () => {
    const ranges = getRanges(timeSelection);
    if (ranges.length === 1) {
      const steps = (timeSelection.length - ranges[0][1] + ranges[0][0] - 1) % timeSelection.length;
      setTimeSelection(translation(timeSelection, steps));
    } else {
      setTimeSelection(translation(timeSelection, -1));
    }
  };

  useInterval(setNextTime, timePlaying ? timeSpeed : undefined);

  const [
    { present: spaceSelection },
    {
      set: setSpaceSelection,
      undo: undoSpaceSelection,
      redo: redoSpaceSelection,
      canUndo: canUndoSpaceSelection,
      canRedo: canRedoSpaceSelection,
    },
  ] = useUndo<SelectionDirectionType>({
    origins: [],
    destinations: [],
  });

  const timeSelectionQuery = useMemo(
    () =>
      timeslotsId !== undefined
        ? [...Array.from({ length: timeSelection.length }).keys()]
            .filter((x) => timeSelection[x])
            .map((x) => timeslotsId[x])
        : [],
    [timeSelection, timeslotsId]
  );

  const isValid = useMemo(() => {
    if (
      areasName === undefined ||
      spaceSelection.origins.length === 0 ||
      spaceSelection.destinations.length === 0
    ) {
      return true;
    }

    let hasInner = false;
    for (const originId of spaceSelection.origins) {
      if (areasName[originId].main) {
        hasInner = true;
      }
    }
    for (const destinationId of spaceSelection.destinations) {
      if (areasName[destinationId].main) {
        hasInner = true;
      }
    }
    return hasInner;
  }, [areasName, spaceSelection]);

  useEffect(() => {
    if (dataLevel && mainIndicator !== undefined) {
      setSpaceSelection({ origins: [], destinations: [] });
      setTimeSelection(
        Array.from({ length: mainIndicator.timeslots }, (value, index) =>
          index === 15 || index === 16 ? true : false
        )
      );
    }
  }, [mainIndicator, dataLevel, setSpaceSelection]);

  useEffect(() => {
    if (!isValid) {
      const toastId = toast.warn(intl.fluidity.space.invalid_selection, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      return () => {
        toast.dismiss(toastId);
      };
    }
  }, [isValid, intl]);

  const [direction, setDirection] = useState<"origin" | "destination" | "both">("origin");

  const [mapMode, setMapMode] = useState<"choropleth" | "bubblemap" | "flowmap" | "node" | "none">(
    defaultMapMode
  );
  const [mapSelectedDirection, setMapSelectedDirection] = useState<"origin" | "destination">(
    "destination"
  );

  const mapDirectionIsAutomatic = useMemo(
    () => spaceSelection.origins.length === 1 || spaceSelection.destinations.length === 1,
    [spaceSelection]
  );

  const mapDirection = useMemo<"origin" | "destination">(() => {
    return mapSelectedDirection === "origin" || spaceSelection.destinations.length === 1
      ? "origin"
      : "destination";
  }, [mapSelectedDirection, spaceSelection.destinations.length]);

  const [filterSelection, setFilterSelection] = useImmerState<FilterSelection>({
    indicator: null,
    indicatorIndex: null,
    classes: [],
  });

  useEffect(() => {
    if (mainIndicator) {
      setFilterSelection((oldFilter) => {
        oldFilter.indicator = mainIndicator.id;
        oldFilter.indicatorIndex = 0;
        oldFilter.classes = [true];
      });
    }
  }, [mainIndicator, setFilterSelection]);

  useEffect(() => {
    if (indicators !== undefined && mapMode === "node") {
      const indicator = indicators.find((indicator) => indicator.identifier === "node_flow");
      if (indicator !== undefined) {
        const index = indicators.indexOf(indicator);
        setFilterSelection((oldFilter) => {
          oldFilter.indicator = indicator.id;
          oldFilter.indicatorIndex = index;
          oldFilter.classes = indicator.categories.map(() => true);
        });
      }
    }
  }, [indicators, mapMode, setFilterSelection]);

  const originsCentroid = useMemo(() => {
    if (areasCentroid !== null && spaceSelection.origins.length === 1 && isValid) {
      return areasCentroid[spaceSelection.origins[0]];
    }
    return undefined;
  }, [areasCentroid, isValid, spaceSelection.origins]);

  const destinationsCentroid = useMemo(() => {
    if (areasCentroid !== null && spaceSelection.destinations.length === 1 && isValid) {
      return areasCentroid[spaceSelection.destinations[0]];
    }
    return undefined;
  }, [areasCentroid, isValid, spaceSelection.destinations]);

  const { data: flowProperty } = useQuery(
    "indicator",
    projectId !== null &&
      dataLevel !== null &&
      versionSelection !== null &&
      mainIndicator !== undefined && {
        parameters: {
          project: projectId,
        },
        body: {
          origins: spaceSelection.origins,
          destinations: spaceSelection.destinations,
          indicator: mainIndicator.id,
          version: versionSelection,
        },
      },
    propertiesQ.getIndicator,
    {
      staleTime: Number.POSITIVE_INFINITY,
      cacheTime: 3_600_000,
      refetchOnWindowFocus: false,
    }
  );

  const publicTransportParameters = useMemo(() => {
    if (originsCentroid && destinationsCentroid) {
      if (flowProperty !== undefined) {
        let max = 0;
        let maxIndex = -1;

        for (let index = 0; index <= timeSelection.length; index++) {
          if (timeSelection[index] && flowProperty[index][0] && flowProperty[index][0] > max) {
            max = flowProperty[index][0];
            maxIndex = index;
          }
        }
        const dayMoment = maxIndex !== -1 ? maxIndex / flowProperty.length : 0.5;
        return {
          from: originsCentroid,
          to: destinationsCentroid,
          departure: addSeconds(startOfISOWeek(new Date()), secondsInDay * dayMoment).toISOString(),
        };
      } else {
        return {
          from: originsCentroid,
          to: destinationsCentroid,
          departure: addSeconds(startOfISOWeek(new Date()), secondsInDay * 0.5).toISOString(),
        };
      }
    }

    return undefined;
  }, [destinationsCentroid, originsCentroid, flowProperty, timeSelection]);

  const { data: publicTransportJourneys } = useQuery(
    "navitia-journeys",
    publicTransportParameters,
    journeysQ.get
  );

  useKeyPress(["ctrl.z", "meta.z"], (event: KeyboardEvent) => {
    if (event.shiftKey === false && canUndoSpaceSelection === true) {
      undoSpaceSelection();
    } else if (event.shiftKey === true && canRedoSpaceSelection === true) {
      redoSpaceSelection();
    }
  });

  useKeyPress(["ctrl.y", "meta.y"], () => {
    if (canRedoSpaceSelection === true) {
      redoSpaceSelection();
    }
  });

  return {
    isValid,
    timeSelection,
    timeSelectionQuery,
    setTimeSelection,
    spaceSelection,
    setSpaceSelection,
    direction,
    setDirection,
    mapSelectedDirection,
    setMapSelectedDirection,
    filterSelection,
    setFilterSelection,
    publicTransportJourneys,
    mapMode,
    setMapMode,
    ranges,
    setAllTime,
    setPreviousTime,
    setNextTime,
    timePlaying,
    toggleTimePlaying,
    timeSpeed,
    setTimeSpeed,
    mapDirectionIsAutomatic,
    mapDirection,
  };
}
