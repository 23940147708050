import { makeFullFragment } from "../utils";
import utils from "../en-gb/utils";

export default makeFullFragment<typeof utils>({
    error: {
        500: "Erreur interne du serveur",
        503: "Les serveurs Entropy sont actuellement hors service",
        modal_title: "ERREUR",
        unknown: "Erreur inconnue du serveur",
    },
    generic: {
        cancel: "Annuler",
        close: "Fermer",
        confirm: "Confirmer",
        delete: "Supprimer",
        enable: "Activer",
        disable: "Désactiver",
        edit: "Modifier",
        no: "Non",
        save: "Sauvegarder",
        yes: "Oui",
        refresh: "Rafraîchir",
    },
    password: {
        error_length: "Le mot de passe doit faire au moins 10 caractères",
        error_mismatch: "Les deux mots de passe ne sont pas identiques",
        strength_invalid: "Mot de passe invalide",
        strength_weak: "Mot de passe faible",
        strength_good: "Mot de passe fort",
        strength_strong: "Mot de passe très fort",
    },
});
