import React from "react";
import { ThemeProvider } from "styled-components";

import { Provider, useStore } from "reto";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";

import IntlStore from "./intl";
import Routes from "./routes";
import ApplicationStore from "./stores/application";
import TooltipProvider from "./components/tooltips/provider";
import ErrorFallback from "./scenes/error";
import AuthenticateStore from "./stores/authenticate";
import UserStore from "./stores/user";
import { FlowdysseaStore } from "./remotes/flowdyssea";

export default function App() {
  const { appTheme } = useStore(ApplicationStore);
  const navigate = useNavigate();

  return (
    <Provider of={IntlStore}>
      <ThemeProvider theme={appTheme}>
        <TooltipProvider>
          <Provider of={AuthenticateStore} memo>
            <Provider of={FlowdysseaStore} memo>
              <Provider of={UserStore} memo>
                {process.env.NODE_ENV !== "development" ? (
                  <Sentry.ErrorBoundary
                    fallback={(error) => (
                      <ErrorFallback
                        eventId={error.eventId}
                        resetError={error.resetError}
                        navigate={navigate}
                      />
                    )}
                  >
                    <Routes />
                  </Sentry.ErrorBoundary>
                ) : (
                  <Routes />
                )}
              </Provider>
            </Provider>
          </Provider>
        </TooltipProvider>
      </ThemeProvider>
    </Provider>
  );
}
