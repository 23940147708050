import {
  useQuery as useQueryOld,
  // useQueries as useQueriesOld,
  // useInfiniteQuery as useInfiniteQueryOld,
  // UseInfiniteQueryOptions as UseInfiniteQueryOptionsOld,
  useMutation as useMutationOld,
  UseMutationOptions as UseMutationOptionsOld,
  UseQueryOptions as UseQueryOptionsOld,
  // QueryObserverResult,
  QueryClient,
} from "react-query";
import { Wretcher } from "wretch";

export const queryClient = new QueryClient();

/** Endpoint to be used with useQuery, useQueries, useInfiniteQuery or useMutation. */
export type Endpoint<TArguments, TResponse> = {
  /** Base Wretcher instance. */
  base: Wretcher;
  /** Function to call a query. */
  fn: (parameters: TArguments) => Promise<TResponse>;
};

/**
 * Wrapper around [useQuery](https://react-query.tanstack.com/guides/queries).
 * Sends a fetch query (GET, POST) to a remote server.
 *
 * @param key Query key used for invalidation.
 * @param parameters Parameters to pass along the query.
 * @param endpoint Endpoint to query against.
 * @param options Options for original useQuery, minus queryKey, queryFn and enabled.
 */
export function useQuery<TArguments, TResponse>(
  key: string,
  parameters: TArguments | boolean | undefined,
  endpoint: Endpoint<TArguments, TResponse>,
  options?: Omit<
    UseQueryOptionsOld<TResponse, unknown, TResponse>,
    "queryKey" | "queryFn" | "enabled"
  >
) {
  const functionWrapper = (context: { queryKey: (string | TArguments)[] }) =>
    endpoint.fn(context.queryKey[1] as TArguments);

  return useQueryOld([key, parameters as unknown as TArguments], functionWrapper, {
    ...options,
    enabled: endpoint.base !== undefined && parameters !== undefined && parameters !== false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
}

/** useQueries query parameters. */
export type UseQueriesQuery<TArguments, TResponse> = {
  /** Query key used for invalidation. */
  key: string;
  /** Parameters to pass along the query. */
  parameters: TArguments | undefined | false;
  /** Options for original useQueries, minus queryKey, queryFn and enabled. */
  options?: Omit<
    UseQueryOptionsOld<TResponse, unknown, TResponse>,
    "queryKey" | "queryFn" | "enabled"
  >;
};

/**
 * Wrapper around [useQueries](https://react-query.tanstack.com/guides/parallel-queries).
 * Sends multiple fetch queries (GET, POST) to a remote server.
 *
 * @param endpoint Endpoint to query against.
 * @param queries Queries parameters and options.
 */
// export function useQueries<TArgument, TResponse>(
//   endpoint: Endpoint<TArgument, TResponse>,
//   queries: readonly UseQueriesQuery<TArgument, unknown>[]
// ) {
//   const functionWrapper = (context: { queryKey: [string, TArgument] }) =>
//     endpoint.fn(context.queryKey[1]);
//   return useQueriesOld(
//     queries.map((execution) => ({
//       ...execution.options,
//       queryKey: [execution.key, execution.parameters],
//       queryFn: functionWrapper,
//       enabled:
//         endpoint.base !== undefined &&
//         execution.parameters !== undefined &&
//         execution.parameters !== false,
//     }))
//   ) as QueryObserverResult<TResponse, unknown>[];
// }

/**
 * Wrapper around [useInfiniteQuery](https://react-query.tanstack.com/guides/infinite-queries).
 * Sends a paginated fetch query (GET, POST) to a remote server.
 *
 * @param key Query key used for invalidation.
 * @param parameters Parameters to pass along the query.
 * @param endpoint Endpoint to query against.
 * @param injectNewPageParam Inject new page param into function parameters. Page param type
 * must be precised explicitely as the Typescript compiler is not capable of guessing it
 * from options.getNextPageParam and options.getPreviousPageParam.
 * @param options Options for original useInfiniteQuery, minus queryKey, queryFn and enabled.
 */
// export function useInfiniteQuery<TArguments, TResponse, TPageParameter>(
//   key: string,
//   parameters: TArguments | boolean | undefined,
//   endpoint: Endpoint<TArguments, TResponse>,
//   injectNewPageParameter: (parameters: TArguments, pageParameter: TPageParameter) => TArguments,
//   options?: Omit<
//     UseInfiniteQueryOptionsOld<TResponse, unknown, TResponse>,
//     "getNextPageParam" | "getPreviousPageParam" | "queryKey" | "queryFn" | "enabled"
//   > & {
//     getNextPageParam?: (result: TResponse) => TPageParameter;
//     getPreviousPageParam?: (result: TResponse) => TPageParameter;
//   }
// ) {
//   const functionWrapper = (context: {
//     queryKey: (string | TArguments)[];
//     pageParam?: TPageParameter;
//   }) =>
//     endpoint.fn(
//       context.pageParam !== undefined
//         ? injectNewPageParameter(context.queryKey[1] as TArguments, context.pageParam)
//         : (context.queryKey[1] as TArguments)
//     );

//   return useInfiniteQueryOld([key, parameters as TArguments], functionWrapper, {
//     ...options,
//     enabled: endpoint.base !== undefined && parameters !== undefined && parameters !== false,
//     getNextPageParam: options?.getNextPageParam,
//     getPreviousPageParam: options?.getPreviousPageParam,
//   } as any);
// }

/**
 * Wrapper around [useMutation](https://react-query.tanstack.com/guides/mutations).
 * Sends a mutation query (POST, PUT, DELETE) to a remote server.
 *
 * @param endpoint Endpoint to query against.
 * @param options Options for original useMutation.
 */
export function useMutation<TArguments, TResponse>(
  endpoint: Endpoint<TArguments, TResponse>,
  options?: UseMutationOptionsOld<TResponse, unknown, TArguments, TResponse>
) {
  return useMutationOld(endpoint.fn, options);
}
