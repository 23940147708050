import { useState, useCallback } from "react";

import produce, { Draft } from "immer";

export type ImmerSetter<T> = (setter: (draft: Draft<T>) => void | T) => void;

export default function useImmerState<T>(
  initialValue: T | (() => T)
): [T, (setter: (draft: Draft<T>) => void | T) => void] {
  const [state, setState] = useState(initialValue);
  const setUsingImmer = useCallback(
    (setter: (draft: Draft<T>) => void | T) => {
      setState(produce(setter) as unknown as (previousState: T) => T);
    },
    [setState]
  );

  return [state, setUsingImmer];
}
