import { makeFullDictionary } from "../utils";
import defaultDict from "../en-gb";
import dashboard from "./dashboard";
import fluidity from "./fluidity";
import log from "./log";
import other from "./other";
import utils from "./utils";

export default makeFullDictionary<typeof defaultDict>({
    language: "fr-FR",
    messages: {
        dashboard: dashboard,
        error: other.error,
        fluidity: fluidity,
        log: log,
        not_found: other.not_found,
        utils: utils,
    },
});
