import { useRef, useCallback } from "react";

import jwt_decode from "jwt-decode";

interface Token {
  getBearer: () => string | null;
  getEncoded: () => string | null;
  getExpiration: () => number;
  setEncoded: (newEncodedValue?: string | null) => void;
  isValid: () => boolean;
  isAdmin: () => boolean;
}

function getTokenExp(encoded: string | null) {
  const decoded = encoded ? (jwt_decode(encoded) as { exp: number }) : null;
  return decoded ? decoded.exp * 1000 : 0;
}

function getTokenBearer(encoded: string | null) {
  return encoded ? "Bearer " + encoded : null;
}

function getTokenRole(enc: string | null) {
  return enc ? (jwt_decode(enc) as unknown as { role: string }).role : "USER";
}

export function useToken(initialEncodedValue: string | null = null): Token {
  const enc = useRef(initialEncodedValue);
  const exp = useRef(getTokenExp(enc.current));
  const bearer = useRef(getTokenBearer(enc.current));

  const getBearer = useCallback(() => {
    return bearer.current;
  }, []);

  const getEncoded = useCallback(() => {
    return enc.current;
  }, []);

  const getExpiration = useCallback(() => {
    return exp.current;
  }, []);

  const isValid = useCallback(() => {
    return exp.current - Date.now() > 0;
  }, []);

  const isAdmin = useCallback(() => {
    return getTokenRole(enc.current) === "ADMIN";
  }, []);

  const setEncoded = useCallback((newEncodedValue: string | null = null) => {
    if (newEncodedValue !== enc.current) {
      enc.current = newEncodedValue;
      exp.current = getTokenExp(newEncodedValue);
      bearer.current = getTokenBearer(newEncodedValue);
    }
  }, []);

  return {
    getBearer,
    getEncoded,
    getExpiration,
    isValid,
    isAdmin,
    setEncoded,
  };
}
