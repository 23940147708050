import { makeDefaultFragment } from "../utils";

export default makeDefaultFragment({
    error: {
        500: "Internal server error",
        503: "Entropy servers are currently down",
        modal_title: "ERROR",
        unknown: "Unknown server error",
    },
    generic: {
        cancel: "Cancel",
        close: "Close",
        confirm: "Confirm",
        delete: "Delete",
        enable: "Enable",
        disable: "Disable",
        edit: "Edit",
        no: "No",
        save: "Save",
        yes: "Yes",
        refresh: "Refresh",
    },
    password: {
        error_length: "Password must be at least 10 characters long",
        error_mismatch: "Password confirmation does not match",
        strength_invalid: "Invalid password",
        strength_weak: "Weak password",
        strength_good: "Good password",
        strength_strong: "Strong password",
    },
});
