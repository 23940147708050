import { Wretcher } from "wretch";

import { type, describePost } from "../descriptors";

export const describeFlowdysseaTable = (wretcher: Wretcher) => ({
  count: describePost({
    base: wretcher,
    url: "/table/count",
    body: type<{
      project: string;
      origins: string[];
      destinations: string[];
      times: string[];
      unit: "volume" | "percentage";
      globalFilter: null | string;
      filters: { key: string; type: string; values: number[] }[];
    }>(),
    response: type<TableCount>(),
  }),
  get: describePost({
    base: wretcher,
    url: "/table/rows",
    body: type<{
      project: string;
      origins: string[];
      destinations: string[];
      times: string[];
      pageSize: number;
      pageIndex: number;
      unit: "volume" | "percentage";
      globalFilter: null | string;
      filters: { key: string; type: string; values: number[] }[];
      sortBy: { id: string; desc: boolean }[];
    }>(),
    response: type<TableRows>(),
  }),
});
