import {
  ParametersBaseType,
  QueryBaseType,
  ResponseBaseType,
  result,
  query,
  format,
  RestEndpoint,
  error,
  RestEndpointConfig,
} from "./common";

export function describeGet<
  TParameters extends ParametersBaseType = undefined,
  TQuery extends QueryBaseType = undefined,
  TResponse extends ResponseBaseType = undefined
>(
  config: RestEndpointConfig<TParameters, TQuery, undefined, TResponse> & { arrayBuffer?: boolean }
) {
  return {
    base: config.base,
    fn: (options: { parameters?: TParameters; query?: TQuery }) =>
      result(
        error(
          query(
            format(config.base, config.url, options && options.parameters),
            options && options.query
          ),
          config.handleError
        ).get(),
        config.response,
        config.arrayBuffer
      ),
  } as RestEndpoint<TParameters, TQuery, undefined, TResponse>;
}
