import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  colors: {
    main: "#831246",
    secondary: "#31538f",
    tertiary: "#282051",
    grey4: "#44546a",
    grey3: "#888888",
    grey2: "#d8d8d8",
    grey1: "#e7e6e6",
    white: "#ffffff",
    black: "#323232",
    origin: "#6aae21",
    destination: "#831246",
    both: "#0088e4",
  },
  sizes: {
    smallBorder: "0.125rem",
  },
};

export const darkTheme: DefaultTheme = {
  colors: {
    main: "#831246",
    secondary: "#31538f",
    tertiary: "#BFBDBA",
    grey4: "#d8d8d8",
    grey3: "#888888",
    grey2: "#44546a",
    grey1: "#323232",
    white: "#000000",
    black: "#e7e6e6",
    origin: "#6aae21",
    destination: "#ca3432",
    both: "#0088e4",
  },
  sizes: {
    smallBorder: "0.125rem",
  },
};
