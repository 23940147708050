import React, { useCallback, useMemo, createContext, useEffect } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

import useImmerState from "../../hooks/utilities/immer-state";
import LoadingSpinner from "../loading/loading-spinner";
import { callModalCallback } from "./utils";
import { BaseModalOptions, ModalProperties } from "./base-modal";

/** Div on top of the app, containing the overlay and all modals */
const WrapperDiv = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;

/** Div containing the grey transparent overlay */
const ModalOverlayDiv = styled("div")<{ zIndex: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${(properties) => properties.zIndex};
  background-color: ${(properties) => properties.theme.colors.grey1};
  opacity: 0.5;
`;

/** Div going over the top modal when it is in a loading state */
const LoadingDiv = styled("div")<{ zIndex: number }>`
  z-index: ${(properties) => properties.zIndex};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  & > div {
    min-height: 10rem;
    min-width: 20rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${(properties) => properties.theme.colors.white};
    border-radius: 1rem;
  }

  & > div > span {
    color: ${(properties) => properties.theme.colors.grey4};
  }

  & > div > button {
    position: absolute;
    background: none;
    border: none;
    top: 0.5rem;
    right: 0.2rem;
  }
`;

/** Informations tied to a single rendered modal */
export type ModalInstance<OptionsType extends BaseModalOptions> = {
  /** React component used to render the modal using given properties */
  renderer: (properties: ModalProperties<OptionsType>) => JSX.Element;
  /** Modal basic properties such as the id, the open function, etc */
  properties: ModalProperties<OptionsType>;
};

/**
 * This is used to indicate a list of ModalInstance with different OptionsType. In Java, the type of
 * that list would look like: ```ModalInstance<? extends BaseModalOptions>[]``` which would allow to
 * store entries with different subtypes. Since there is no wildcard and no equivalent in
 * TypeScript yet, the only workaround is to use any.
 *
 * See this issue: https://github.com/microsoft/TypeScript/issues/1213 (Higher Kinded Types would
 * also allow to resolve this issue).
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WildcardOptions = any;

/** Functions returned by a modal context provider */
type ModalsContextType = {
  /** Add (open) a modal on top of the app and other existing modals */
  add: (value: ModalInstance<WildcardOptions> | null) => number;
  /** Update an existing modal informations using its id */
  update: (value: ModalInstance<WildcardOptions>, id: number) => void;
  /** Delete (close) an existing modal using its id */
  delete: (id: number) => void;
};

export const Context = createContext<ModalsContextType>({
  add: () => {
    return 0;
  },
  update: () => {
    return;
  },
  delete: () => {
    return;
  },
});
Context.displayName = "ModalsContext";

/**
 * Context provider to handle modals.
 * It provides functions to add, update and delete one or multiple modals and handles the rendering
 * and ordering of modals.
 */
export default function ModalsProvider(properties: {
  anchor: HTMLDivElement | null;
  children: React.ReactNode | React.ReactNode[] | undefined;
}) {
  /** List of currently rendered modals */
  const [list, setList] = useImmerState<(ModalInstance<WildcardOptions> | null)[]>([]);

  /** Last modal informations, which should be on top */
  const lastModal = useMemo(() => {
    return list.length > 0 ? list[list.length - 1] : null;
  }, [list]);

  /** Last modal identifier in the list */
  const lastModalId = useMemo(() => {
    if (list.length > 0) {
      const element = list[list.length - 1];
      return element !== null ? element.properties.id : -1;
    } else {
      return -1;
    }
  }, [list]);

  /**
   * CSS z-index of the grey transparent overlay. The overlay should be behind the top modal but
   * on top of all other modals and on top of the whole app.
   */
  const overlayZIndex = useMemo(() => {
    if (lastModal === null || lastModalId === -1) {
      return 1000;
    } else {
      return lastModal.properties.options.isLoading === undefined
        ? lastModalId * 2 - 1
        : lastModalId * 2 + 1;
    }
  }, [lastModal, lastModalId]);

  /** Add (open) a new modal on top */
  const addModal = useCallback(
    (value: ModalInstance<WildcardOptions> | null) => {
      const newId = lastModalId + 1;
      setList((draft) => {
        draft.push(value);
      });
      return newId;
    },
    [setList, lastModalId]
  );

  /** Update an existing modal informations */
  const updateModal = useCallback(
    (value: ModalInstance<WildcardOptions> | null, id: number) => {
      setList((draft) => {
        draft[id] = value;
      });
    },
    [setList]
  );

  /** Delete (close) a given modal. Can delete any modal, even if it is not on top */
  const deleteModal = useCallback(
    (id: number) => {
      setList((draft) => {
        const index = draft.findIndex(
          (element) => element !== null && element.properties.id === id
        );
        draft.splice(index, 1);
      });
    },
    [setList]
  );

  /**
   * Functions passed to the children components using context so that they can add, update and
   * remove modals.
   */
  const setter = useMemo(
    () => ({
      add: addModal,
      update: updateModal,
      delete: deleteModal,
    }),
    [addModal, updateModal, deleteModal]
  );

  /**
   * Close the top modal if the user clicks outsite of it and if the modal is configured to do so.
   */
  const onOverlayClick = useCallback(() => {
    if (
      lastModal !== null &&
      lastModal.properties.options.isLoading === undefined &&
      lastModal.properties.options.closeOnOverlayClick &&
      (!lastModal.properties.options.onOverlayClick ||
        callModalCallback(lastModal.properties.options.onOverlayClick))
    ) {
      lastModal.properties.close();
    }
  }, [lastModal]);

  /**
   * Close the modal if the user pressed the Escape key and if the modal is configured to do so.
   */
  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent) => {
      if (
        lastModal !== null &&
        lastModal.properties.options.closeOnEscapeKey &&
        event.key === "Escape" &&
        (!lastModal.properties.options.onEscapeKey ||
          callModalCallback(lastModal.properties.options.onEscapeKey))
      ) {
        lastModal.properties.close();
      }
    },
    [lastModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent);
    };
  }, [handleKeyboardEvent]);

  return (
    <Context.Provider value={setter}>
      {properties.anchor !== null &&
        list.length > 0 &&
        ReactDOM.createPortal(
          <WrapperDiv>
            <ModalOverlayDiv zIndex={overlayZIndex} onClick={onOverlayClick} />
            {list.map(
              (element) =>
                element !== null && (
                  <element.renderer {...element.properties} key={element.properties.id} />
                )
            )}
            {lastModal !== null && lastModal.properties.options.isLoading !== undefined && (
              <LoadingDiv zIndex={overlayZIndex + 1}>
                <div>
                  {lastModal.properties.options.canCancelLoading && (
                    <button
                      onClick={() => {
                        if (
                          lastModal.properties.options.onCancelLoading === undefined ||
                          callModalCallback(lastModal.properties.options.onCancelLoading)
                        ) {
                          lastModal.properties.close();
                        }
                      }}
                    >
                      X
                    </button>
                  )}
                  <LoadingSpinner />
                  <span>{lastModal.properties.options.isLoading}</span>
                </div>
              </LoadingDiv>
            )}
          </WrapperDiv>,
          properties.anchor
        )}
      {properties.children}
    </Context.Provider>
  );
}
