import { useRef, useState, useCallback, useMemo, useContext, useEffect } from "react";

import { Context, ModalInstance } from "./context";
import { ModalCallback, callModalCallback } from "./utils";

/** Basic modal options which can be used in any modal and are handled by the context provider */
export type BaseModalOptions = {
  /** Can the user cancel the loading state using a close button? */
  canCancelLoading: boolean;
  /** Should the modal close when the user presses Escape? */
  closeOnEscapeKey: boolean;
  /** Should the modal close when the user clicks on the overlay? */
  closeOnOverlayClick: boolean;
  /** Callback called when the user cancels the loading state. The loading won't be cancelled if the
   * callback returns false.
   */
  onCancelLoading?: ModalCallback;
  /** Callback called when the modal is closed by the user or programmatically. The modal won't be
   * closed if the callback returns false.
   */
  onClose?: ModalCallback;
  /** Callback called when the user pressed the Escape key (if closeOnEscapeKey is true). The modal
   * won't be closed if the callback returns false.
   */
  onEscapeKey?: ModalCallback;
  /** Callback called when the modal is opened by the user or programmatically. The modal won't be
   * closed if the callback returns false.
   */
  onOpen?: ModalCallback;
  /** Callback called when the user clicks on the overlay (if closeOnOverlayClick is true). The
   * modal won't be closed if the callback returns false.
   */
  onOverlayClick?: ModalCallback;
  /** Display a loading indicator on top of the modal with a message unless set to undefined */
  isLoading?: string;
};

/** Modal properties forwarded to the modal context */
export type ModalProperties<OptionsType extends BaseModalOptions> = {
  /** Close the modal */
  close: () => void;
  /** Modal identifier */
  id: number;
  /** Open the modal */
  open: () => void;
  /** Modal options */
  options: OptionsType;
  /** Close or open the modal according to its state */
  toggle: () => void;
};

/**
 * Base hook for implementing a custom modal hook.
 *
 * @param renderer React component function to render a modal using given options
 * @param options Modal options used by the modal provider
 */
export default function useBaseModal<OptionsType extends BaseModalOptions>(
  renderer: (properties: ModalProperties<OptionsType>) => JSX.Element,
  options: OptionsType
): Pick<ModalProperties<OptionsType>, "close" | "open" | "toggle"> {
  const { add: addModal, update: updateModal, delete: deleteModal } = useContext(Context);

  const { onOpen, onClose } = options;

  const [id, setId] = useState<number | null>(null);
  const isVisibleReference = useRef(false);

  /** Public function to open the modal */
  const open = useCallback(async () => {
    if (!isVisibleReference.current && (!onOpen || (await callModalCallback(onOpen)))) {
      isVisibleReference.current = true;
      const newId = addModal(null);
      setId(newId);
    }
  }, [onOpen, addModal, setId]);

  /** Public function to close the modal */
  const close = useCallback(async () => {
    if (isVisibleReference.current && (!onClose || (await callModalCallback(onClose)))) {
      isVisibleReference.current = false;
      deleteModal(id as number);
      setId(null);
    }
  }, [onClose, deleteModal, setId, id]);

  /** Public function to open or close the modal according to its current state */
  const toggle = useCallback(() => {
    if (isVisibleReference.current) {
      close();
    } else {
      open();
    }
  }, [open, close]);

  /** Modal instanced passed to the context provider */
  const modal = useMemo<ModalInstance<OptionsType>>(() => {
    return {
      renderer: renderer,
      properties: {
        options: options,
        id: id !== null ? id : -1000,
        open,
        close,
        toggle,
      },
    };
  }, [options, id, open, close, renderer, toggle]);

  /** Update the modal every time one of its property changes */
  useEffect(() => {
    if (id !== null && isVisibleReference.current) {
      updateModal(modal, id);
    }
  }, [updateModal, id, modal]);

  return { open, close, toggle };
}
