import { makeDefaultFragment, required, optional } from "../utils";

export default makeDefaultFragment({
    bookmarks: {
        default_name: ["Search from {date}", { date: required<string>() }],
        details: {
            comment_delete_confirm: "Do you really want to delete this comment?",
            comment_delete_tooltip: "Delete this comment",
            comment_edit_tooltip: "Edit this comment",
            comment_placeholder: "Type a comment here...",
            comment_send_tooltip: "Send this comment",
            comment_title: "Comments",
            copy: "Copy this bookmark",
            copy_confirm: `Are you sure you want to create a personnal copy of this bookmark? This will also copy its
            comments from you and other users. Changes you make to this copy won't affect the shared bookmark.`,
            copy_duplicate_error:
                "A bookmark with the same origins and destinations already exists among your bookmarks.",
            copy_keep_comments: "Copy current comments",
            copy_tooltip: "This will create a personnal copy of this shared bookmark",
            close: "Close",
            description: "This bookmark has no description yet.",
            settings: "Settings",
            share: "Share this bookmark",
            share_confirm: `Are you sure you want to share this bookmark with your organisation? Anyone will be able to see
            its content and change it. They will also be able to add their own comments.`,
            share_duplicate_error:
                "A bookmark with the same origins and destinations already exists among share bookmarks.",
            share_keep_comments: "Share your own current comments",
            share_keep_original: "Delete the current bookmark",
            share_tooltip: `This will remove this bookmark from your personal bookmarks and share it with your organisation.`,
        },
        entry: {
            comments_tooltip: [
                `There {count, plural,
                    =0 {is no new comment}
                    =1 {is one new comment}
                    other {are # new comments}
                }. Click here to view all comments.`,
                { count: required<number>() },
            ],
            counter_both_tooltip: [
                `There {count, plural,
                    =0 {is no district}
                    =1 {is one district}
                    other {are # districts}
                } selected as both origin and destination on this bookmark.`,
                { count: required<number>() },
            ],
            counter_destinations_tooltip: [
                `There {count, plural,
                    =0 {is no district}
                    =1 {is one district}
                    other {are # districts}
                } selected as destination on this bookmark.`,
                { count: required<number>() },
            ],
            counter_origins_tooltip: [
                `There {count, plural,
                    =0 {is no district}
                    =1 {is one district}
                    other {are # districts}
                } selected as origin on this bookmark.`,
                { count: required<number>() },
            ],
            dnd_tooltip: "Drag to reorder this bookmark.",
            hide_button_tooltip: `Hide this bookmark.`,
            load: "load",
            load_tooltip: "Load the selection of this bookmark",
            loaded: "loaded",
            loaded_already_tooltip:
                "This bookmark is already loaded ; you can interact with it at the top of this list.",
            settings_button_tooltip: "Change this bookmark's settings.",
            share_on_tooltip: `This bookmark is shared. Click to configure its sharing settings.`,
            share_off_tooltip: `This bookmark is personal. Click to configure its sharing
                settings.`,
            shortcut_tooltip: [
                "Use {shortcut} to load this bookmark",
                { shortcut: required<string>() },
            ],
            show_button_tooltip: `Unhide this bookmark.`,
        },
        no_bookmark_title: "No bookmark added",
        no_bookmark_description:
            "You can add the current selection to a new bookmark by clicking the star icon located top left of the Selection tab.",
        no_bookmark_shortcut:
            "Then you will be able to assign a keyboard shortcut to it such as [Ctrl]+[1] by changing its settings.",
        notifications: {
            added: "Selection added to the bookmarks tab.",
            already_exists: [
                "A bookmark named {name} with the exact same selection already exists.",
                { name: required<string>() },
            ],
            confirm: "OK",
            loaded: "Selection updated.",
            no_bookmark: "No bookmark has been assigned to this shortcut.",
            no_selection: "Select at least an origin or a destination to add a bookmark.",
            shortcut_changed: [
                "New shorcut: [CTRL] + [{shortcut}]",
                { shortcut: required<number>() },
            ],
        },
        search: "Search",
        settings: {
            button_quit: "Close",
            button_save: "Confirm",
            delete: {
                cancel: "Cancel",
                confirm: "Confirm",
                label: "Delete the bookmark",
                label_short: "Delete",
            },
            description_label: "Description",
            keyboard_add_tooltip: ["Assign to key [{key}]", { key: required<number>() }],
            keyboard_label: "Keyboard shortcut",
            keyboard_none: "None",
            keyboard_none_tooltip: "Remove keyboard shortcut",
            keyboard_override: {
                cancel: "Cancel",
                confirm: "Confirm",
                message: [
                    "The keyboard shortcut Ctrl + {shortcut} is already used to load {bookmark}, do you want to override it?",
                    {
                        shortcut: required<number>(),
                        bookmark: required<string>(),
                    },
                ],
            },
            name_label: "Bookmark name",
            name_placeholder: "No name",
            title: "Bookmark settings",
        },
        show_hidden_bookmarks: `Show hidden bookmarks`,
        tab_title: "Bookmarks",
    },
    flow: {
        daily_flow: [
            "{flow} daily moves",
            {
                flow: required<React.ReactNode>(),
            },
        ],
        daily_mean: "Daily mean",
        no_journey_found: "No public transport journey found!",
        selected_flow: [
            "{selected} moves of {total} daily moves",
            {
                selected: required<React.ReactNode>(),
                total: required<React.ReactNode>(),
            },
        ],
        selected_time: [
            "{begin} to {end}",
            {
                begin: required<React.ReactNode>(),
                end: required<React.ReactNode>(),
            },
        ],
        selected_multi_time: [
            "{ranges} ranges: {time}",
            {
                ranges: required<React.ReactNode>(),
                time: required<React.ReactNode>(),
            },
        ],
    },
    indicators: {
        age: {
            label: "Age ranges",
            label_short: "Age",
            label_0: "Aged 0 to 24",
            label_1: "Aged 25 to 44",
            label_2: "Aged 45 to 64",
            label_3: "Aged more than 65",
            icon_0: "0-24",
            icon_1: "25-44",
            icon_2: "45-64",
            icon_3: "65+",
        },
        gender: {
            label: "Genders",
            label_short: "Gender",
            label_0: "Female",
            label_1: "Male",
            icon_0: "F",
            icon_1: "M",
        },
        spc: {
            label: "Socio professional categories",
            label_short: "SPC",
            label_0: "Without professional activities",
            label_1: "Farmer",
            label_2: "Artisan, trader, entrepreneur",
            label_3: "Executive, higher intellectual profession",
            label_4: "Intermediate profession",
            label_5: "Employee",
            label_6: "Worker",
            icon_0: "Other",
            icon_1: "Farm",
            icon_2: "ATE",
            icon_3: "Exec",
            icon_4: "Inter",
            icon_5: "Empl",
            icon_6: "Work",
        },
        transport_mode: {
            label: "Transport modes",
            label_short: "Mode",
            label_0: "Car",
            label_1: "Public transport",
            label_2: "Motorbike",
            label_3: "Soft modes",
            label_4: "Bike",
        },
        ges: {
            label: "Greenhouse gas",
            label_short: "GHG (CO₂e)",
            label_0: "Car",
            label_1: "Bus",
            label_2: "Motorbike",
            label_3: "Soft modes",
            label_4: "Total",
        },
        travel_purpose: {
            label: "Travel purposes",
            label_short: "Purpose",
            label_0: "Home",
            label_1: "Work",
            label_2: "Studies",
            label_3: "Leisures",
            label_4: "Shopping",
            label_5: "Others",
        },
    },
    map: {
        filter_button: "Filters",
        filter_button_tooltip: "Filter using a property",
        filter_toggle_all: "All",
        show: "Show",
        show_button_tooltip: "Show map options",
        hide_button_tooltip: "Hide map options",
        tab_title: "Map",
        download: "Download the map",
        file_name_placeholder: "map_'{date'}",
        export_title: "Export map",
        with_legend: "With the legend",
        zoom_in_tooltip: "Zoom in",
        zoom_out_tooltip: "Zoom out",
        zoom_project_tooltip: "Zoom on the project",
        zoom_selection_tooltip: "Zoom on the selection",
        hover_show_tooltip: "Show hover data",
        hover_hide_tooltip: "Hide hover data",
        public_show_tooltip: "Show best public transport journey",
        public_hide_tooltip: "Hide best public transport journey",
        public_invalid_selection: "Select an origin and a destination",
        public_journey_notfound: "No public transport journey available for this selection",
        public_common_steptime: "Section",
        public_common_totaltime: "Total",
        public_street_walk: "Walking section",
        public_street_bike: "Bike section",
        public_street_taxi: "Taxi section",
        public_street_car: "Car section",
        public_street_crow_fly: " (crow fly)",
        flowmap_equal: "outgoing ≅ incoming",
        flowmap_out: "more outgoing",
        flowmap_in: "more incoming",
        choropleth_origins_tooltip: "Choropleth show flow from origins",
        choropleth_destinations_tooltip: "Choropleth show flow to destinations",
        mode_button_tooltip: "Change flow display mode",
        mode_choropleth: "Choropleth",
        mode_choropleth_tooltip: "Show flow using shades of green or red",
        mode_bubblemap: "Bubblemap",
        mode_node: "Node",
        mode_node_tooltip: "Show flow passing by node",
        mode_bubblemap_tooltip: "Show flow using bubbles",
        mode_flowmap: "Flowamp",
        mode_flowmap_tooltip: "Show flow using arrows",
        mode_none: "Hide",
        mode_none_tooltip: "Do not show any flow indicator",
    },
    options: {
        background_label: "Map theme",
        font_size: "Font size",
        custom_map: "Mapbox style link",
        background: {
            light: "Light",
            dark: "Dark",
            streets: "Streets",
            scenic: "Scenic",
            entropy: "Sand",
            satellite_streets: "Satellite Streets",
            outdoors: "Outdoors",
            navigation_day: "Navigation Day",
            navigation_night: "Navigation Night",
            basic_chilled: "Basic Chilled",
        },
        buttons: {
            close: "Close",
            dashboard: "Dashboard",
            logout: "Log out",
            open: "Options",
        },
        current_project: "Current project",
        language_label: "Language",
        playback_label: "Playback speed",
        theme: {
            dark: "Dark",
            light: "Light",
        },
        theme_label: "Application theme",
        title: "Settings",
    },
    resume: {
        buttons: {
            close_all: "Close all",
            close_all_tooltip: "Close all property below",
            open_all: "Open all",
            open_all_tooltip: "Open all property below",
            visibility_tooltip: "Set visibility of property below",
            visibility_all: "All",
            visibility_all_tooltip: "Show or hide all properties",
            visibility_indicator_tooltip: [
                "Show or hide {indicator} property",
                { indicator: required<string>() },
            ],
            download_tooltip: "Download a report (coming soon)",
            percentage_tooltip: "Show in percentage",
            volume_tooltip: "Show in volume",
            previous_tooltip: "Previous time slot",
            next_tooltip: "Next time slot",
            all_day_tooltip: "Select all day",
            play_tooltip: "Play",
            pause_tooltip: "Pause",
        },
        journeys: {
            label: [
                `{index, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}
                 journey
                `,
                { index: required<number>() },
            ],
            tooltip: [
                `{index, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}
                 public transport journey
                `,
                { index: required<number>() },
            ],
            visibility_tooltip: [
                `Hide {index, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}
                 public transport journey
                `,
                { index: required<number>() },
            ],
            departure: "Left from:",
            departure_tooltip: "Time of departure (monday of this week)",
            arrival: "Arrived at:",
            arrival_tooltip: "Time of arrival",
            duration_tooltip: "Total duration of the journey",
            distance_tooltip: "Total length of the journey",
            street_walk: "Walking section",
            street_bike: "Bike section",
            street_taxi: "Taxi section",
            street_car: "Car section",
            street_crow_fly: " (crow fly)",
        },
        no_indicators: `There is no property selected at all. Please select at least
        one property from the top-left dropdown menu.`,
        tab_title: "Flow",
    },
    space: {
        all_destinations: "All destinations",
        all_origins: "All origins",
        buttons: {
            bookmark_delete_cancel: "Cancel",
            bookmark_delete_confirm: "Confirm",
            bookmark_delete_message: [
                "Current selection is already bookmarked as {name}, do you want to delete the bookmark?",
                {
                    name: required<string>(),
                },
            ],
            bookmark_tooltip: "Add selection to bookmarks",
            both_label: "All directions",
            both_tooltip: "Select using both origins and destination",
            destination: "D",
            destination_label: "Destination",
            destination_tooltip: "Select using destinations",
            invert_tooltip: "Invert origins and destinations",
            level_district: "D",
            level_district_label: "District",
            level_district_tooltip: "Select using districts",
            level_town: "T",
            level_town_label: "Town",
            level_town_tooltip: "Select using towns",
            level_inter: "I",
            level_inter_label: "Intercommunal",
            level_inter_tooltip: "Select using intercommunal zones",
            level_switch_tooltip: "Data granularity level",
            origin: "O",
            origin_label: "Origin",
            origin_tooltip: "Select using origins",
            reset: "Empty all",
            reset_destinations_tooltip: "Empty destinations",
            reset_origins_tooltip: "Empty origins",
            reset_tooltip: "Empty selection",
        },
        level_district_add: "Add or delete a district...",
        level_town_add: "Add or delete a town...",
        level_inter_add: "Add or delete an intercommunal zone...",
        search_no_result: "No results",
        tab_title: "Selection",
        invalid_selection: "Your selection is invalid, it contains data missing from your project.",
    },
    table: {
        controls: {
            colorize_tooltip_on: "Colorize cells according to their values",
            colorize_tooltip_off: "Remove cell colors",
            reset_tooltip: "Reset table",
            visibility_tooltip: "Show and hide columns",
            page: "Page",
            page_size: "Results per page",
            loading: "Loading",
            download_tooltip: "Download table data",
            reset: {
                cancel: "Cancel",
                confirm: "Confirm",
                message:
                    "Do you really want to reset all ordering, filters and search fields on the table?",
            },
            lines: [
                "{lines} {lines, plural, one {row} other {rows}}",
                {
                    lines: optional(0),
                },
            ],
            toggle_all_columns: "All columns",
            search: "Search",
        },
        export: {
            buttons: {
                cancel: "Cancel",
                download: "Download",
            },
            file_name: "New file name",
            file_name_placeholder: "table_'{date'}",
            loading: "Converting table to file...",
            show_all_columns: "Show all columns",
            show_time_index: "Show time index",
            title: "Export table into file",
        },
        header: {
            destination: "Destination",
            code: "Code",
            flow: "Flow",
            flow_volume: "Volume",
            name: "Name",
            origin: "Origin",
            time: "Time",
            time_begin: "Start",
            time_end: "End",
        },
        headerMenu: {
            between: "Between...",
            between0: "Minimum",
            between1: "Maximum",
            eq: "Equal to ...",
            eq0: "Value(s) to match",
            firsts: "10 firsts ...",
            filter: "Filter column",
            greater: "Greater than ...",
            greater0: "Minimum value",
            greater_or_eq: "Greater than or equal to ...",
            greater_or_eq0: "Minimum value",
            lesser: "Lesser than ...",
            lesser0: "Maximum value",
            lesser_or_eq: "Lesser than or equal to ...",
            lesser_or_eq0: "Maximum value",
            no_filter: "No filter",
            not_eq: "Not equal to ...",
            not_eq0: "Value(s) to exclude",
            over_avg: "Values over average",
            placeholder: "Pick a filter",
            reset: "Reset filters and order",
            search: "Search among all columns",
            sortAsc: "Ascending sort",
            sortDesc: "Descending sort",
            title: "Order and filter",
            under_avg: "Values under average",
        },
        tab_title: "Table",
    },
});
