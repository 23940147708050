import intlMessageFormat from "intl-messageformat";

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type Widen<T> = T extends string
  ? string
  : T extends number
  ? number
  : T extends boolean
  ? boolean
  : T;

type AdvancedMessageWithParameters<T extends { [key: string]: unknown }> =
  | [string, T]
  | [string, string, T];

type AdvancedMessage =
  | [string]
  | [string, string]
  | AdvancedMessageWithParameters<{ [key: string]: unknown }>;

type DefaultMessage = string | AdvancedMessage | DefaultMessageList;

type DefaultMessageList = { [key: string]: DefaultMessage };

type FullMessage<T extends DefaultMessage> = T extends {
  [key: string]: DefaultMessage;
}
  ? { [P in keyof T]: FullMessage<T[P]> }
  : string;

type PartialMessage<T extends DefaultMessage> = T extends {
  [key: string]: DefaultMessage;
}
  ? { [P in keyof T]?: PartialMessage<T[P]> }
  : string | undefined;

type DefaultDictionary = {
  language: string;
  messages: DefaultMessage;
};

type FullDictionary<T extends DefaultDictionary> = {
  language: string;
  messages: FullMessage<T["messages"]>;
};

type PartialDictionary<T extends DefaultDictionary> = {
  language: string;
  messages: PartialMessage<T["messages"]>;
};

type TranslatorWithParameters<T extends { [key: string]: unknown }> = T[keyof T] extends
  | string
  | number
  | boolean
  | Date
  ? (parameters: T) => string
  : T[keyof T] extends React.ReactNode
  ? (parameters: T) => React.ReactNode
  : (parameters: T) => (T[keyof T] | string)[];

type Translator<T extends DefaultMessage> = T extends { [key: string]: DefaultMessage }
  ? TranslatorList<T>
  : T extends AdvancedMessageWithParameters<infer U>
  ? TranslatorWithParameters<U>
  : string;

type TranslatorList<T extends DefaultMessageList> = { [key: string]: Translator<T[string]> };

function messageIsString(messages: DefaultMessage): messages is string {
  return typeof messages === "string";
}

function messageIsAdvanced(messages: DefaultMessage): messages is AdvancedMessage {
  return Array.isArray(messages);
}

/**
 * Create a default dictionary.
 * A default dictionary contains definitions of messages which can contain parameters. It is
 * used as a baseline for other dictionaries of type FullDictionary or PartialDictionary.
 * @param dict Valid default dictionary
 */
export const makeDefaultDictionary = <T extends DefaultDictionary>(dict: T) => dict;

/**
 * Create a fragment to be used in a default dictionary.
 * @param frag List of messages
 */
export const makeDefaultFragment = <T extends DefaultMessage>(frag: T) => frag;

/**
 * Create a dictionary which inherits from a DefaultDictionary and translate every message.
 * Messages and their parameters should be described in the DefaultDictionary but not in a
 * FullDictionary.
 * @template T DefaultDictionary to inherit from
 * @param dict Valid dictionary with every message translated
 */
export const makeFullDictionary = <T extends DefaultDictionary>(dict: FullDictionary<T>) => dict;

/**
 * Create a fragment to be used in a full or partial dictionary, which translates every message.
 * @template T List of messages to translate
 * @param frag Translated list of messages
 */
export const makeFullFragment = <T extends DefaultMessage>(frag: FullMessage<T>) => frag;

/**
 * Create a dictionary which inherits from a DefaultDictionary and translate every or some messages.
 * Messages and their parameters should be described in the DefaultDictionary but not in a
 * PartialDictionary.
 * @template T DefaultDictionary to inherit from
 * @param dict Valid dictionary with some messages translated
 */
export const makePartialDictionary = <T extends DefaultDictionary>(dict: PartialDictionary<T>) =>
  dict;

/**
 * Create a fragment to be used in a partial dictionary, which translates some messages.
 * @template T List of messages to translate
 * @param frag Translated list of messages
 */
export const makePartialFragment = <T extends DefaultMessage>(frag: PartialMessage<T>) => frag;

const makeTranslatorFromMessage = <T extends DefaultMessage>(
  defaultMessage: T,
  defaultLanguage: string,
  messages: (PartialMessage<T> | FullMessage<T>)[],
  languages: string[]
): Translator<T> => {
  if (messageIsString(defaultMessage)) {
    for (let index = messages.length - 1; index >= 0; index--) {
      if (messages[index] !== undefined) {
        return new intlMessageFormat(
          messages[index] as string,
          languages[index]
        ).format() as Translator<T>;
      }
    }
    return new intlMessageFormat(defaultMessage, defaultLanguage).format() as Translator<T>;
  } else if (messageIsAdvanced(defaultMessage)) {
    for (let index = messages.length - 1; index >= 0; index--) {
      if (messages[index] !== undefined) {
        return ((values: Record<string, unknown>) =>
          new intlMessageFormat(messages[index] as string, languages[index]).format(
            values
          )) as Translator<T>;
      }
    }
    return ((values: Record<string, unknown>) =>
      new intlMessageFormat(defaultMessage[0], defaultLanguage).format(values)) as Translator<T>;
  } else {
    const result = {} as TranslatorList<DefaultMessageList>;
    for (const [defaultKey, defaultValue] of Object.entries(defaultMessage) as Entries<{
      [key: string]: DefaultMessage;
    }>) {
      const values: (PartialMessage<T> | FullMessage<T>)[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const message of messages as any[]) {
        const value = message[defaultKey];
        if (value !== undefined) {
          values.push(value);
        }
      }
      result[defaultKey] = makeTranslatorFromMessage(
        defaultValue,
        defaultLanguage,
        values,
        languages
      );
    }
    return result as Translator<T>;
  }
};

export const makeTranslator = <T extends DefaultDictionary>(
  defaultDict: T,
  ...dicts: (PartialDictionary<T> | FullDictionary<T>)[]
) => {
  return makeTranslatorFromMessage(
    defaultDict.messages,
    defaultDict.language,
    dicts.map((dict) => dict.messages),
    dicts.map((dict) => dict.language)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
};

/**
 * Define a required parameter for a message.
 * @param description Short text to describe the parameter.
 * @example { name: required<string>("User first name.") }
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function required<T = undefined>(description?: string) {
  return undefined as unknown as T;
}

/**
 * Define an optional parameter for a message.
 * @param defaultParameter Parameter default value.
 * @example { name: optional<string>("Jean") }
 */
export function optional<T>(defaultParameter: T): Widen<T>;

/**
 * Define an optional parameter for a message.
 * @param description Short text to describe the parameter.
 * @param defaultParameter Parameter default value.
 * @example { name: optional<string>("User first name.", "Jean") }
 */
export function optional<T>(description: string, defaultParameter: T): Widen<T>;

export function optional<T>(parameter1: string | T, parameter2?: T) {
  return parameter2 === undefined ? (parameter1 as Widen<T>) : (parameter2 as Widen<T>);
}
