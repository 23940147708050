import React, { useCallback } from "react";
import styled from "styled-components";

import { useStore } from "reto";
import { useNavigate } from "react-router";

import logoSource from "./../assets/png/common/logo_color.png";
import IntlStore from "../intl";
import LanguageSelectSmall from "../components/controls/language-select-small";

const RootContainer = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: ${(properties) => properties.theme.colors.grey1};
`;

const BannerImage = styled("img")`
  display: block;
  width: 800px;
  max-width: 80vw;
  margin: 2rem auto;
`;

const LanguagesContainer = styled("div")`
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

const Title = styled("span")`
  font-size: 3rem;
  color: ${(properties) => properties.theme.colors.destination};
`;

export default function NotFoundPage() {
  const { intl } = useStore(IntlStore);
  const navigate = useNavigate();

  const backToDashboard = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <RootContainer>
      <LanguagesContainer>
        <LanguageSelectSmall />
      </LanguagesContainer>
      <BannerImage src={logoSource} alt={intl.log.in.banner_alt} onClick={backToDashboard} />
      <Title>{intl.not_found.title}</Title>
      <br />
      <span>
        {intl.not_found.message({
          link: (
            <a href="https://entropy.sc/contact" key="1">
              {intl.not_found.message_link}
            </a>
          ),
        })}
      </span>
      <br />
      <button onClick={backToDashboard}>{intl.not_found.dashboard}</button>
    </RootContainer>
  );
}
