import {
  ParametersBaseType,
  QueryBaseType,
  ResponseBaseType,
  result,
  query,
  format,
  RestEndpoint,
  RestEndpointConfig,
  error,
} from "./common";

export function describeDelete<
  TParameters extends ParametersBaseType = undefined,
  TQuery extends QueryBaseType = undefined,
  TResponse extends ResponseBaseType = undefined
>(config: RestEndpointConfig<TParameters, TQuery, undefined, TResponse>) {
  return {
    base: config.base,
    fn: (options: { parameters?: TParameters; query?: TQuery }) =>
      result(
        error(
          query(
            format(config.base, config.url, options && options.parameters),
            options && options.query
          ),
          config.handleError
        ).delete(),
        config.response
      ),
  } as RestEndpoint<TParameters, TQuery, undefined, TResponse>;
}
