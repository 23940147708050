import { Wretcher } from "wretch";

import { type, describeDelete, describeGet, describePost, describePut } from "../descriptors";

export type Project = {
  name: string;
};

export type RemoteProject = {
  id: string;
  name: string;
  createOn: string;
  updateOn: string;
};

export type Level = {
  name: string;
  timeslots: number;
  categories: number;
  type: string;
  aggregation: "sum" | "mean";
  main: boolean;
};

export type Levels = {
  [key: string]: Level[];
};

export const describeFlowdysseaProjects = (wretcher: Wretcher) => ({
  create: describePost({
    base: wretcher,
    url: "/projects",
    body: type<Project>(),
  }),

  fetch: describeGet({
    base: wretcher,
    url: "/projects?limit=100&page=0",
    response: type<FlowdysseaProjectType[]>(),
  }),

  // fetchByOrganisation: describeGet({
  //   base: wretcher,
  //   url: "/organisations/<organisation>/projects/",
  //   parameters: type<{ organisation: string }>(),
  //   response: type<{ projects: RemoteProject[] }>(),
  // }),

  // fetchByUser: describeGet({
  //   base: wretcher,
  //   url: "/users/<user>/projects/",
  //   parameters: type<{ user: string }>(),
  //   response: type<{ projects: RemoteProject[] }>(),
  // }),

  get: describeGet({
    base: wretcher,
    url: "/projects/<project>",
    parameters: type<{ project: string }>(),
    response: type<RemoteProject>(),
  }),

  getProjectUsers: describeGet({
    base: wretcher,
    url: "/projects/<project>/users",
    parameters: type<{ project: string }>(),
    response: type<FlowdysseaProjectUserType[]>(),
  }),

  getIndicators: describeGet({
    base: wretcher,
    url: "/projects/<project>/indicators",
    parameters: type<{ project: string }>(),
    response: type<FlowdysseaIndicatorType[]>(),
  }),

  getConfiguration: describeGet({
    base: wretcher,
    url: "/projects/<project>/configuration",
    parameters: type<{ project: string }>(),
    response: type<ProjectConfigurationType>(),
  }),

  getVersions: describeGet({
    base: wretcher,
    url: "/projects/<project>/versions",
    parameters: type<{
      project: string;
    }>(),
    response: type<
      {
        id: string;
        project: string;
        version: [string, string];
      }[]
    >(),
  }),

  getStatistics: describeGet({
    base: wretcher,
    url: "/projects/<project>/resume/statistics?versionBegin=<versionBegin>&versionEnd=<versionEnd>",
    parameters: type<{
      project: string;
      versionBegin: string;
      versionEnd: string;
    }>(),
    response: type<{
      [keyof: string]: {
        population: number;
        worker_home?: number;
        worker_activity?: number;
        student_home?: number;
        student_activity?: number;
      };
    }>(),
  }),

  getGeobuf: describeGet({
    base: wretcher,
    url: "/projects/<project>/geobuf?granularity=<granularity>&resolution=<resolution>&versionBegin=<versionBegin>&versionEnd=<versionEnd>",
    parameters: type<{
      project: string;
      granularity: string;
      versionBegin: string;
      resolution: string;
      versionEnd: string;
    }>(),
    response: type<ArrayBuffer>(),
    arrayBuffer: true,
  }),

  getAreasName: describeGet({
    base: wretcher,
    url: "/projects/<project>/areas/name?versionBegin=<versionBegin>&versionEnd=<versionEnd>",
    parameters: type<{ project: string; versionBegin: string; versionEnd: string }>(),
    response: type<{ [keyof: string]: FlowdysseaAreaNameType }>(),
  }),

  getTimes: describeGet({
    base: wretcher,
    url: "/times/timeslots?timeslots=<timeslots>&versionBegin=<versionBegin>&versionEnd=<versionEnd>",
    parameters: type<{ timeslots: number; versionBegin: string; versionEnd: string }>(),
    response: type<FlowdysseaTimeType[]>(),
  }),

  // getGeojson: describeGet({
  //   base: wretcher,
  //   url: "/projects/<project>/geojson",
  //   parameters: type<{ project: string }>(),
  //   query: type<{ level: AdministrativeLevel; chunk: number }>(),
  //   response: type<AreaFeatureCollectionType>(),
  // }),

  update: describePut({
    base: wretcher,
    url: "/projects/<project>",
    parameters: type<{ project: string }>(),
    body: type<Project>(),
  }),

  delete: describePut({
    base: wretcher,
    url: "/projects/<project>",
    parameters: type<{ project: string }>(),
  }),

  addUser: describePut({
    base: wretcher,
    url: "/projects/<project>/users/<user>",
    parameters: type<{ project: string; user: string }>(),
  }),

  addUsers: describePost({
    base: wretcher,
    url: "/projects/<project>/users",
    parameters: type<{ project: string }>(),
    body: type<{ users: string[] }>(),
  }),

  removeUser: describeDelete({
    base: wretcher,
    url: "/projects/<project>/users/<user>",
    parameters: type<{ project: string; user: string }>(),
  }),
});
