import React, { useState, useRef, useCallback, useEffect, useMemo, createContext } from "react";
import styled from "styled-components";

import useDomReference from "../../hooks/utilities/dom-reference";
import { TooltipStyle } from "./bookmark";

/** Overlay over the whole screen to place the tooltip in. */
const Container = styled("div")`
  z-index: 10000;
  pointer-events: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

/** TooltipProvider props. */
export interface TooltipProviderProperties {
  /**
   * Delay in milliseconds before showing the tooltip after the mouse pointer is on the target.
   * Default to 300ms.
   */
  delay?: number;
  /** Tooltip max width before automatically wrapping text. */
  maxWidth?: string;
  /** Function determining the tooltip position. */
  positioner?: TooltipProviderPositioner;
  /** React children node(s). */
  children: React.ReactNode | React.ReactNode[];
}

/** Function to determine a tooltip's absolute position. */
export type TooltipProviderPositioner = (
  /** Type of positioning ("top", "bottom", etc). */
  positionning: string,
  /** Target node rectangle with its screen coordinates and size. */
  targetRect: DOMRect,
  /** Tooltip node rectangle with its screen coordinates and size. */
  tooltipRect: DOMRect
) => {
  /** New tooltip screen X coordinate. */
  x: number;
  /** New tooltip screen Y coordinate. */
  y: number;
};

/** Contains an imperative way to set the tooltip's content. Used by TooltipWrapper. */
export const TooltipContext = createContext<{
  /** Set content. */
  setContent: (caller: Element | null, value: React.ReactNode) => void;
  /** Unset content. */
  unsetContent: (caller: Element | null) => void;
}>({
  setContent: () => {
    return;
  },
  unsetContent: () => {
    return;
  },
});
TooltipContext.displayName = "TooltipContext";

/** Default TooltipProvider positioner. */
export function tooltipProviderDefaultPositioner(
  positionning: string,
  targetRect: DOMRect,
  tooltipRect: DOMRect
) {
  let x = 0;
  let y = 0;
  switch (positionning) {
    case "top":
      x = targetRect.left + targetRect.width / 2 - tooltipRect.width / 2;
      y = targetRect.top - tooltipRect.height;
      break;
    case "left":
      x = targetRect.left - tooltipRect.width;
      y = targetRect.top + targetRect.height / 2 - tooltipRect.height / 2;
      break;
    case "right":
      x = targetRect.right;
      y = targetRect.top + targetRect.height / 2 - tooltipRect.height / 2;
      break;
    default:
      // bottom
      x = targetRect.left + targetRect.width / 2 - tooltipRect.width / 2;
      y = targetRect.bottom;
      break;
  }
  if (x < 0) {
    x = 0;
  } else {
    const delta = x + tooltipRect.width - window.innerWidth;
    if (delta > 0) {
      x -= delta;
    }
  }
  if (y < 0) {
    y = 0;
  } else {
    const delta = y + tooltipRect.height - window.innerHeight;
    if (delta > 0) {
      y -= delta;
    }
  }
  return { x, y };
}

/**
 * Wrap an entire application to add custom tooltip capabilities.
 *
 * A tooltip can be added to any element under this provider using HTML attributes starting with
 * "data-title". Those attributes' values can contain simple text or HTML elements:
 * ```
 * <span data-title="<b>I'm the tooltip!</b>">Hover me to show the tooltip</span>
 * ```
 *
 * Tooltips are positioned by default at the bottom of the target element. Positioning can be
 * changed by appending a value to the HTML attribute's name:
 * ```
 * <button data-title-left="Tooltip here!">Hover me to show a tooltip to my left</button>
 * ```
 *
 * To use a React component inside the tooltip, wrap your target element with TooltipWrapper:
 * ```
 * <Tooltip content={<CustomComponent>Tooltip</CustomComponent>} positioning="right">
 *   <div>My tooltip has a custom component in it!</div>
 * </Tooltip>
 * ```
 */
export default function TooltipProvider(properties: TooltipProviderProperties) {
  const config = useMemo(() => {
    return {
      delay: 300,
      maxWidth: "25rem",
      positioner: tooltipProviderDefaultPositioner,
      ...properties,
    };
  }, [properties]);

  const [isVisible, setIsVisible] = useState(false);
  const setIsVisibleTimeout = useRef<NodeJS.Timeout>();

  const [target, setTarget] = useState<{
    content?: string;
    positioning: string;
    node: Element;
  } | null>(null);

  const tooltipReference = useDomReference<HTMLDivElement>();

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (tooltipReference.get) {
        let node = document.elementFromPoint(event.clientX, event.clientY);
        if (setIsVisibleTimeout.current) {
          clearTimeout(setIsVisibleTimeout.current);
          setIsVisibleTimeout.current = undefined;
        }
        setIsVisible(false);
        while (node) {
          for (const attribute of node.attributes) {
            if (attribute.name.startsWith("data")) {
              if (attribute.name === "data-tooltip-wrapper") {
                setTarget({
                  positioning: attribute.value,
                  node,
                });
                setIsVisibleTimeout.current = setTimeout(() => setIsVisible(true), config.delay);
                return;
              } else if (attribute.name.startsWith("data-title") && attribute.value !== "") {
                const attributeNameParts = attribute.name.split("-");
                const positioning = attributeNameParts.length >= 3 ? attributeNameParts[2] : "";
                setTarget({
                  content: attribute.value,
                  positioning,
                  node,
                });
                setIsVisibleTimeout.current = setTimeout(() => setIsVisible(true), config.delay);
                return;
              }
            }
          }
          node = node.parentElement;
        }
      }
    },
    [config, tooltipReference, setIsVisibleTimeout, setTarget]
  );

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousedown", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mousedown", handleMouseMove);
    };
  }, [handleMouseMove]);

  const position =
    target && tooltipReference.get
      ? config.positioner(
          target.positioning,
          target.node.getBoundingClientRect(),
          tooltipReference.get.getBoundingClientRect()
        )
      : { x: 0, y: 0 };

  const [customContentList, SetCustomContentList] = useState<
    { caller: Element | null; value: React.ReactNode }[]
  >([]);

  const setContent = useCallback(
    (caller: Element | null, value: React.ReactNode) => {
      SetCustomContentList((state) => [...state, { caller, value }]);
    },
    [SetCustomContentList]
  );

  const unsetContent = useCallback(
    (caller: Element | null) => {
      SetCustomContentList((state) => {
        const newState = [...state];
        const toRemove = newState.findIndex((element) => element.caller === caller);
        newState.splice(toRemove, 1);
        return newState;
      });
    },
    [SetCustomContentList]
  );

  const customContent = useMemo(() => {
    const toShow = customContentList.find((element) => element.caller === target?.node);
    return toShow?.value;
  }, [target, customContentList]);

  return (
    <TooltipContext.Provider value={{ setContent, unsetContent }}>
      <Container>
        {target?.content ? (
          <TooltipStyle
            ref={tooltipReference.set}
            maxWidth={config.maxWidth}
            show={isVisible}
            x={position.x}
            y={position.y}
            dangerouslySetInnerHTML={{ __html: target.content }}
          />
        ) : (
          <TooltipStyle
            ref={tooltipReference.set}
            maxWidth={config.maxWidth}
            show={isVisible}
            x={position.x}
            y={position.y}
          >
            {customContent}
          </TooltipStyle>
        )}
      </Container>
      {properties.children}
    </TooltipContext.Provider>
  );
}
