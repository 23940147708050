export function toString(value: number, base: number) {
  return value / base >= 1 ? value.toString() : "0" + value.toString();
}

const si = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "k" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "G" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
];
const rx = /\.0+$|(\.\d*[1-9])0+$/;

export function nFormatter(number: number, digits: number) {
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (number >= si[index].value) {
      break;
    }
  }
  return (
    (number / si[index].value).toFixed(digits).replace(rx, "$1").replace(".", ",") +
    si[index].symbol
  );
}
