import React, { useCallback, useContext, useEffect } from "react";

import styled from "styled-components";

import { TooltipContext } from "./provider";
import useDomReference from "../../hooks/utilities/dom-reference";

type TooltipStyleProperties = {
  maxWidth: string;
  show: boolean;
  x: number;
  y: number;
};

export const TooltipStyle = styled("div")<TooltipStyleProperties>`
  position: absolute;
  visibility: ${(properties) => (properties.show ? "visible" : "hidden")};
  opacity: ${(properties) => (properties.show ? "1" : "0")};
  left: ${(properties) => properties.x}px;
  top: ${(properties) => properties.y}px;
  background-color: ${(properties) => properties.theme.colors.grey3};
  border: 0.1rem solid ${(properties) => properties.theme.colors.grey1};
  color: ${(properties) => properties.theme.colors.grey1};
  text-align: center;
  pointer-events: none;
  padding: 0.3rem;
  max-width: ${(properties) => properties.maxWidth};
  transition: opacity 0.2s;
`;

/** Tooltip properties. */
type TooltipProperties = {
  /** React node(s) to display inside the tooltip. */
  content: React.ReactNode;
  /** Single React children node to target. */
  children: React.ReactElement;
  /** Tooltip positioning ("left", "top", etc...). */
  positioning?: string;
};

/**
 * Adds a tooltip containing a custom React component to a target node. Usage:
 * ```
 * <Tooltip content={<CustomComponent>Tooltip</CustomComponent>} positioning="right">
 *   <div>My tooltip has a custom component in it!</div>
 * </Tooltip>
 * ```
 */
function TooltipBookmark(properties: TooltipProperties) {
  const { setContent, unsetContent } = useContext(TooltipContext);

  const childrenReference = useDomReference<HTMLElement>();
  const children = React.cloneElement(properties.children, {
    ref: childrenReference.set,
    "data-tooltip-wrapper": properties.positioning ?? "",
  });

  const onMouseEnter = useCallback(() => {
    setContent(childrenReference.get, properties.content);
  }, [childrenReference, properties.content, setContent]);

  const onMouseLeave = useCallback(() => {
    unsetContent(childrenReference.get);
  }, [childrenReference, unsetContent]);

  useEffect(() => {
    const child = childrenReference.get;
    if (child) {
      child.addEventListener("mouseenter", onMouseEnter);
      child.addEventListener("mouseleave", onMouseLeave);
      return () => {
        if (child) {
          child.removeEventListener("mouseenter", onMouseEnter);
          child.removeEventListener("mouseleave", onMouseLeave);
        }
      };
    }
  }, [childrenReference, onMouseEnter, onMouseLeave]);

  return children;
}

export default TooltipBookmark;
