import { useCallback, useEffect, useState } from "react";

import { useStore } from "reto";
import { useLocalStorageState } from "ahooks";

import { useMapOption } from "../hooks/utilities/map-option";
import lightIcon from "../assets/png/map_themes/light.png";
import darkIcon from "../assets/png/map_themes/dark.png";
import scenicIcon from "../assets/png/map_themes/scenic.png";
import entropyIcon from "../assets/png/map_themes/entropy.png";
import streetsIcon from "../assets/png/map_themes/streets.png";
import basicChilledIcon from "../assets/png/map_themes/basic_chilled.png";
import navigationNightIcon from "../assets/png/map_themes/navigation_night.png";
import navigationDayIcon from "../assets/png/map_themes/navigation_day.png";
import satelliteStreetsIcon from "../assets/png/map_themes/satellite_streets.png";
import outdoorsIcon from "../assets/png/map_themes/outdoors.png";
import IntlStore from "../intl";

interface MapTheme {
  label: string;
  icon: string;
  url: string;
}

const mapThemeLocalStorage = "mapTheme";
const defaultMapTheme = "sable";
const defaultAutoplaySpeed = 2000;

export default function OptionsStore() {
  const { intl } = useStore(IntlStore);

  const mapTheme = useMapOption(
    new Map<string, MapTheme>([
      [
        "sable",
        {
          label: intl.fluidity.options.background.entropy,
          icon: entropyIcon,
          url: "mapbox://styles/meak/cko8drgek0ljs17mvgrfkbxey",
        },
      ],
      [
        "light_streets",
        {
          label: intl.fluidity.options.background.scenic,
          icon: scenicIcon,
          url: "mapbox://styles/meak/cj8oiikma8rtd2slb32ddhjwy",
        },
      ],
      [
        "streets",
        {
          label: intl.fluidity.options.background.streets,
          icon: streetsIcon,
          url: "mapbox://styles/mapbox/streets-v11",
        },
      ],
      [
        "light",
        {
          label: intl.fluidity.options.background.light,
          icon: lightIcon,
          url: "mapbox://styles/mapbox/light-v10",
        },
      ],
      [
        "dark",
        {
          label: intl.fluidity.options.background.dark,
          icon: darkIcon,
          url: "mapbox://styles/mapbox/dark-v10",
        },
      ],
      [
        "satellite-streets",
        {
          label: intl.fluidity.options.background.satellite_streets,
          icon: satelliteStreetsIcon,
          url: "mapbox://styles/meak/ckzeadme8007h14p9zen1cgta",
        },
      ],
      [
        "outdoors",
        {
          label: intl.fluidity.options.background.outdoors,
          icon: outdoorsIcon,
          url: "mapbox://styles/meak/ckzeaeg4t008b15pcjysfoi60",
        },
      ],
      [
        "navigation-day",
        {
          label: intl.fluidity.options.background.navigation_day,
          icon: navigationDayIcon,
          url: "mapbox://styles/meak/ckzmga3lg003114mtic77konk",
        },
      ],
      [
        "navigation-night",
        {
          label: intl.fluidity.options.background.navigation_night,
          icon: navigationNightIcon,
          url: "mapbox://styles/meak/ckzmgbir400gi14l98cj7tuil",
        },
      ],
      [
        "basic-chilled",
        {
          label: intl.fluidity.options.background.basic_chilled,
          icon: basicChilledIcon,
          url: "mapbox://styles/meak/ckzmgdbc7002d14p400zbkb5e",
        },
      ],
    ]),
    defaultMapTheme
  );

  const [mapThemeLocal, setMapThemeLocal] = useLocalStorageState<string>(mapThemeLocalStorage, {
    defaultValue: "sable",
  });

  useEffect(() => {
    if (
      mapThemeLocal &&
      mapTheme.selectedKey !== mapThemeLocal &&
      mapTheme.map.has(mapThemeLocal)
    ) {
      mapTheme.setSelected(mapThemeLocal);
    }
  }, [mapTheme, mapTheme.selectedVal, mapThemeLocal]);

  // For mapbox testing purposes
  const [mapCustomTheme, setMapCustomTheme] = useState("");
  const [mapCustomEnable, setMapCustomEnable] = useState(false);

  const [autoplaySpeed, setAutoplaySpeedInternal] = useState(defaultAutoplaySpeed);

  const setAutoplaySpeed = useCallback((value: number) => {
    setAutoplaySpeedInternal(value > 1 ? value : 1);
  }, []);

  return {
    mapTheme: mapTheme.selectedVal,
    mapThemeName: mapTheme.selectedKey,
    setMapTheme: setMapThemeLocal,
    mapThemeList: mapTheme.map,
    mapCustomTheme,
    setMapCustomTheme,
    mapCustomEnable,
    setMapCustomEnable,
    autoplaySpeed,
    setAutoplaySpeed,
  };
}
