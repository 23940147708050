import fluidity from "../en-gb/fluidity";
import { makeFullFragment } from "../utils";

export default makeFullFragment<typeof fluidity>({
    bookmarks: {
        details: {
            comment_delete_confirm:
                "Voulez vous vraiment supprimer ce commentaire définitivement ?",
            comment_delete_tooltip: "Supprimer ce commentaire",
            comment_edit_tooltip: "Editer ce commentaire",
            comment_placeholder: "Ecrivez un commentaire...",
            comment_send_tooltip: "Envoyer le commentaire",
            comment_title: "Commentaires",
            copy: "Copier ce favori",
            copy_confirm: `Voulez vous vraiment créer une copie personnelle de ce favori ? Cela copiera aussi les
            commentaires. Les modifications que vous effectuez sur cette copie n'affecteront pas le favori partagé.`,
            copy_duplicate_error:
                "Un favori avec les mêmes origines et destinations existe déjà parmi vos favoris.",
            copy_keep_comments: "Copier aussi les commentaires",
            copy_tooltip: "Cela créera une copie du favori partagé pour votre usage personnel",
            close: "Fermer",
            description: "Ce favori n'a pas encore de description.",
            settings: "Paramètres",
            share: "Partager ce favori",
            share_confirm: `Voulez vous vraiment partager ce favori avec votre organisation ? N'importe quel membre pourra
            voir son contenu et le modifier.`,
            share_duplicate_error:
                "Un favori avec les mêmes origines et destinations existe déjà parmi les favoris partagés.",
            share_keep_comments: "Partager vos commentaires actuels",
            share_keep_original: "Supprimer le favori personnel",
            share_tooltip: `Cela le supprimera de vos favori personnels et le partagera avec les membres de l'organisation.`,
        },
        default_name: "Recherche du {date}",
        entry: {
            comments_tooltip: `Il {count, plural,
                    =0 {n'y a pas de nouveau commentaire}
                    =1 {y a un nouveau commentaire}
                    other {y a # nouveaux commentaires}
                }. Cliquez pour voir tous les commentaires de ce favori.`,
            counter_both_tooltip: `Il {count, plural,
                    =0 {n'y a pas de quartier}
                    =1 {y a un quartier}
                    other {y a # quartiers}
                } sélectionnés en tant qu'origine et destination à la fois.`,
            counter_destinations_tooltip: `Il {count, plural,
                    =0 {n'y a pas de quartier}
                    =1 {y a un quartier}
                    other {y a # quartiers}
                } sélectionnés en tant que destination.`,
            counter_origins_tooltip: `Il {count, plural,
                    =0 {n'y a pas de quartier}
                    =1 {y a un quartier}
                    other {y a # quartiers}
                } sélectionnés en tant qu'origine.`,
            dnd_tooltip: "Glissez-déposez ce bouton pour changer l'ordre du favori.",
            hide_button_tooltip: `Masquer ce favori.`,
            load: "charger",
            load_tooltip: "Charger la sélection de ce favori.",
            loaded: "en cours",
            loaded_already_tooltip:
                "Ce favori est déjà chargé ; vous pouvez intéragir avec en haut de cette liste.",
            settings_button_tooltip: "Changer les proprietés de ce favori.",
            share_on_tooltip: `Ce favori est accessible par toute l'équipe. Cliquez pour configurer
            les options de partage.`,
            share_off_tooltip: `Ce favori n'est accessible qu'à vous. Cliquez pour configurer les
            options de partage.`,
            shortcut_tooltip: "Appuyez sur {shortcut} pour charger ce favori.",
            show_button_tooltip: `Montrer ce favori.`,
        },
        no_bookmark_title: "Aucun favori ajouté",
        no_bookmark_description:
            "Vous pouvez mettre en favori la sélection en cours en cliquant sur l'étoile en haut à droite dans l'onglet Sélection.",
        no_bookmark_shortcut:
            "Vous pourrez ensuite lui assigner un raccourci, par exemple [Ctrl]+[1], en modifiant ses paramètres.",
        notifications: {
            added: "Sélection ajoutée au menu des favoris.",
            already_exists: "Un favori strictement identique nommé {name} existe déjà.",
            confirm: "OK",
            loaded: "Sélection mise à jour.",
            no_bookmark: "Aucun favori n'est assigné a ce raccourci.",
            no_selection:
                "Sélectionnez au moins une origine ou une destination pour ajouter un favori.",
            shortcut_changed: "Nouveau raccourci: [CTRL] + [{shortcut}]",
        },
        search: "Rechercher",
        settings: {
            button_quit: "Fermer",
            button_save: "Valider",
            delete: {
                cancel: "Annuler",
                confirm: "Confirmer",
                label: "Supprimer le favori",
                label_short: "Supprimer",
            },
            description_label: "Description",
            keyboard_add_tooltip: "Assigner la touche [{key}]",
            keyboard_label: "Raccourci clavier",
            keyboard_none: "Aucun",
            keyboard_none_tooltip: "N'assigner aucun raccourci clavier",
            keyboard_override: {
                cancel: "Annuler",
                confirm: "Confirmer",
                message:
                    "Le raccourci Ctrl + {shortcut} est déjà utilisé pour charger {bookmark}, voulez vous l'écraser ?",
            },
            name_label: "Nom du favori",
            name_placeholder: "Aucun nom",
            title: "Paramètres du favori",
        },
        show_hidden_bookmarks: `Montrer les favoris masqués`,
        tab_title: "Favoris",
    },
    flow: {
        daily_flow: "{flow} déplacements journaliers",
        daily_mean: "Moyenne journalière",
        no_journey_found: "Aucun itinéraire en transports en commun trouvé...",
        selected_flow: "{selected} déplacements sur {total} journaliers",
        selected_time: "{begin} à {end}",
        selected_multi_time: "{ranges} créneaux: {time}",
    },
    indicators: {
        age: {
            label: "Tranches d'âge",
            label_short: "Âge",
            label_0: "Âge de 0 à 24",
            label_1: "Âge de 25 à 44",
            label_2: "Âge de 45 à 64",
            label_3: "Âge de 65 et plus",
            icon_0: "0-24",
            icon_1: "25-44",
            icon_2: "45-64",
            icon_3: "65+",
        },
        gender: {
            label: "Genres",
            label_short: "Genre",
            label_0: "Féminin",
            label_1: "Masculin",
            icon_0: "F",
            icon_1: "M",
        },
        spc: {
            label: "Catégories socio-professionnelles",
            label_short: "CSP",
            label_0: "Sans activité professionnelle",
            label_1: "Agriculteurs exploitants",
            label_2: "Artisans, commerçants et chefs d'entreprise",
            label_3: "Cadres et professions intellectuelles supérieures",
            label_4: "Professions Intermédiaires",
            label_5: "Employés",
            label_6: "Ouvriers",
            icon_0: "SAP",
            icon_1: "Agri",
            icon_2: "ACCE",
            icon_3: "CPIS",
            icon_4: "PI",
            icon_5: "Empl",
            icon_6: "Ouvr",
        },
        transport_mode: {
            label: "Modes de transport",
            label_short: "Mode",
            label_0: "Voiture",
            label_1: "Transport en commun",
            label_2: "2 roues motorisées",
            label_3: "Modes doux",
            label_4: "Vélo",
        },
        ges: {
            label: "Gaz à effet de serre",
            label_short: "GES (CO₂e)",
            label_0: "Voiture",
            label_1: "Bus",
            label_2: "2 roues motorisées",
            label_3: "Modes doux",
            label_4: "Total",
        },
        travel_purpose: {
            label: "Motifs de déplacement",
            label_short: "Motif",
            label_0: "Domicile",
            label_1: "Travail",
            label_2: "Étude",
            label_3: "Loisir",
            label_4: "Achat",
            label_5: "Autres",
        },
    },
    map: {
        filter_button: "Filtres",
        filter_button_tooltip: "Filtrer avec un indicateur",
        filter_toggle_all: "Tous",
        show: "Affichage",
        show_button_tooltip: "Montrer les options de la carte",
        hide_button_tooltip: "Masquer les options de la carte",
        tab_title: "Carte",
        zoom_in_tooltip: "Zoomer en avant",
        download: "Télécharger la carte",
        file_name_placeholder: "map_'{date'}",
        export_title: "Exporter la carte",
        with_legend: "Avec la légende",
        zoom_out_tooltip: "Zoomer en arrière",
        zoom_project_tooltip: "Zoomer sur le projet",
        zoom_selection_tooltip: "Zoomer sur la selection",
        hover_show_tooltip: "Afficher les informations de survol",
        hover_hide_tooltip: "Cacher les informations de survol",
        public_show_tooltip: "Montrer le meilleur itinéraire en transports en commun",
        public_hide_tooltip: "Cacher l'itinéraire en transports en commun",
        public_invalid_selection: "Sélectionnez une origine et une destination",
        public_journey_notfound:
            "Aucun itinéraire en transport en commun trouvé pour cette sélection",
        public_common_steptime: "Etape",
        public_common_totaltime: "Total",
        public_street_walk: "Etape à pied",
        public_street_bike: "Etape en vélo",
        public_street_taxi: "Etape en taxi",
        public_street_car: "Etape en voiture",
        public_street_crow_fly: " (à vol d'oiseau)",
        flowmap_equal: "sortant ≅ entrant",
        flowmap_out: "plus de flux sortants",
        flowmap_in: "plus de flux entrants",
        choropleth_origins_tooltip: "Choroplèthe des flux depuis les origines",
        choropleth_destinations_tooltip: "Choroplèthe des flux vers les destinations",
        mode_button_tooltip: "Changer le mode d'affichage",
        mode_node: "Nœud routier",
        mode_node_tooltip: "Montrer le flux routier passant par un nœud",
        mode_choropleth: "Choroplèthe",
        mode_choropleth_tooltip: "Montrer le flux avec des nuances de couleurs",
        mode_bubblemap: "Carte à bulles",
        mode_bubblemap_tooltip: "Montrer le flux avec des bulles",
        mode_flowmap: "Carte à flux",
        mode_flowmap_tooltip: "Montrer le flux avec des flèches",
        mode_none: "Masquer",
        mode_none_tooltip: "Ne pas montrer le flux",
    },
    options: {
        background_label: "Fond de carte",
        font_size: "Taille de police",
        custom_map: "Lien du style mapbox",
        background: {
            light: "Clair",
            dark: "Sombre",
            streets: "Rues",
            scenic: "Scenic",
            entropy: "Sable",
            satellite_streets: "Satellite et rues",
            outdoors: "Extérieur",
            navigation_day: "Navigation jour",
            navigation_night: "Navigation nuit",
            basic_chilled: "Nature",
        },
        buttons: {
            close: "Fermer",
            dashboard: "Dashboard",
            logout: "Déconnexion",
            open: "Options",
        },
        current_project: "Projet actuel",
        language_label: "Langage",
        playback_label: "Vitesse de lecture",
        theme: {
            dark: "Sombre",
            light: "Clair",
        },
        theme_label: "Thème de l'application",
        title: "Options",
    },
    resume: {
        buttons: {
            close_all: "Tout fermer",
            close_all_tooltip: "Fermer toutes les indicateurs",
            open_all: "Tout ouvrir",
            open_all_tooltip: "Ouvrir toutes les indicateurs",
            visibility_tooltip: "Afficher et masquer des indicateurs",
            visibility_all: "Tous",
            visibility_all_tooltip: "Afficher ou masquer tous les indicateurs",
            visibility_indicator_tooltip: "Afficher ou masquer la proprieté {indicator}",
            download_tooltip: "Télécharger un rapport (bientôt disponible)",
            percentage_tooltip: "Afficher en part de déplacements",
            volume_tooltip: "Afficher en volume de déplacements",
            previous_tooltip: "Passer au créneau horaire précédent",
            next_tooltip: "Passer au créneau horaire suivant",
            all_day_tooltip: "Sélectionner toute la journée",
            play_tooltip: "Démarrer le mode automatique",
            pause_tooltip: "Arrêter le mode automatique",
        },
        journeys: {
            label: `{index, selectordinal, one {#er} other {#ème}}
                itinéraire`,
            tooltip: `{index, selectordinal, one {#er} other {#ème}}
                itinéraire en transports en commun`,
            visibility_tooltip: `Masquer le {index, selectordinal, one {#er} other {#ème}}
                itinéraire en transports en commun`,
            departure: "Départ à :",
            departure_tooltip: "Horaire du départ (le lundi de cette semaine)",
            arrival: "Arrivée à :",
            arrival_tooltip: "Horaire d'arrivée",
            duration_tooltip: "Durée totale du voyage",
            distance_tooltip: "Distance totale parcourue lors du voyage",
            street_walk: "A pied",
            street_bike: "En vélo",
            street_taxi: "En taxi",
            street_car: "En voiture",
            street_crow_fly: " (à vol d'oiseau)",
        },
        no_indicators: `Il n'y a aucun indicateur de sélectionné. Merci de choisir
        au moins un indicateur en cliquant sur le bouton de filtre en haut à gauche.`,
        tab_title: "Flux",
    },
    space: {
        all_destinations: "Toutes les destinations",
        all_origins: "Toutes les origines",
        buttons: {
            bookmark_delete_cancel: "Annuler",
            bookmark_delete_confirm: "Confirmer",
            bookmark_delete_message:
                "La sélection est déjà sauvegardée dans le favori {name}, voulez vous supprimer le favori ?",
            bookmark_tooltip: "Ajouter la sélection en favori",
            both_label: "Toutes directions",
            both_tooltip: "Sélectionner par origine et destination",
            destination: "D",
            destination_label: "Destination",
            destination_tooltip: "Sélectionner par destination",
            invert_tooltip: "Inverser les origines et destinations",
            level_district: "Q",
            level_district_label: "Quartier",
            level_district_tooltip: "Sélectionner par quartier",
            level_town: "C",
            level_town_label: "Commune",
            level_town_tooltip: "Sélectionner par commune",
            level_inter: "A",
            level_inter_label: "Agglomération",
            level_inter_tooltip: "Sélectionner par agglomération",
            level_switch_tooltip: "Niveau de granularité des données",
            origin: "O",
            origin_label: "Origine",
            origin_tooltip: "Sélectionner par origine",
            reset: "Vider tout",
            reset_destinations_tooltip: "Vider les destinations",
            reset_origins_tooltip: "Vider les origines",
            reset_tooltip: "Vider la sélection",
        },
        level_district_add: "Ajouter ou supprimer un quartier...",
        level_town_add: "Ajouter ou supprimer une commune...",
        level_inter_add: "Ajouter ou supprimer une agglomération...",
        search_no_result: "Aucun résultat",
        tab_title: "Sélection",
        invalid_selection:
            "Votre sélection est invalide, elle contient des données absentes de votre projet.",
    },
    table: {
        controls: {
            colorize_tooltip_on: "Colorer les cellules en fonction de leur valeur",
            colorize_tooltip_off: "Cacher les couleurs des cellules",
            reset_tooltip: "Réinitialiser les paramètres de la table",
            page: "Page",
            page_size: "Résultats par page",
            loading: "Chargement",
            visibility_tooltip: "Afficher et masquer des colonnes",
            download_tooltip: "Télécharger le contenu de la table",
            reset: {
                cancel: "Annuler",
                confirm: "Confirmer",
                message:
                    "Êtes vous sûr de vouloir réinitialiser les tris, les filtres ainsi que les champ de recherche de la table ?",
            },
            lines: "{lines} {lines, plural, one {ligne} other {lignes}}",
            toggle_all_columns: "Toutes",
            search: "Rechercher parmi toutes les colonnes",
        },
        export: {
            buttons: {
                cancel: "Annuler",
                download: "Télécharger",
            },
            file_name: "Nom du nouveau fichier",
            file_name_placeholder: "table_'{date'}",
            loading: "Conversion de la table ...",
            show_all_columns: "Montrer toutes les colonnes",
            show_time_index: "Montrer l'index temporelle",
            title: "Exporter la table",
        },
        header: {
            destination: "Destination",
            code: "Code",
            flow: "Flux",
            flow_volume: "Volume",
            name: "Nom",
            origin: "Origine",
            time: "Temps",
            time_begin: "Début",
            time_end: "Fin",
        },
        headerMenu: {
            between: "Entre ...",
            between0: "Minimum",
            between1: "Maximum",
            eq: "Egal à ...",
            eq0: "Valeur(s) à égaler",
            firsts: "10 premiers ...",
            filter: "Filtrer la colonne",
            greater: "Plus grand que ...",
            greater0: "Valeur minimale",
            greater_or_eq: "Plus grand ou égal à ...",
            greater_or_eq0: "Valeur minimale",
            lesser: "Plus petit que ...",
            lesser0: "Valeur maximale",
            lesser_or_eq: "Plus petit ou égal à ...",
            lesser_or_eq0: "Valeur maximale",
            no_filter: "Aucun filtre",
            not_eq: "Différent de ...",
            not_eq0: "Valeur(s) à exclure",
            over_avg: "Au dessus de la moyenne",
            placeholder: "Choisissez un filtre",
            reset: "Réinitialiser les filtres et le tri",
            search: "Rechercher",
            sortAsc: "Tri croissant",
            sortDesc: "Tri décroissant",
            title: "Trier et filtrer",
            under_avg: "En dessous de la moyenne",
        },
        tab_title: "Table",
    },
});
