import styled from "styled-components";

export type ModalButtonStyle = "default" | "cancel" | "delete" | undefined;

export const ModalButton = styled("button")<{ variant?: ModalButtonStyle }>`
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  font-size: 1rem;
  margin-left: 0.5rem;
  background: ${(properties) =>
    properties.variant === "cancel"
      ? properties.theme.colors.black
      : properties.variant === "delete"
      ? properties.theme.colors.main
      : properties.theme.colors.tertiary};
  color: ${(properties) => properties.theme.colors.grey1};
  font-family: Gotham;

  &:hover {
    cursor: pointer;
  }
`;

export const ModalForm = styled("div")`
  & > * {
    margin-bottom: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ModalFormLine = styled("div")<{ glueToBottom?: boolean }>`
  align-items: baseline;
  display: flex;
  width: 100%;
  ${(properties) => properties.glueToBottom === true && "margin-bottom: 0.1rem;"}

  & > * {
    margin-left: 0.5rem;
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const ModalFormLabel = styled("label")<{ align?: "left" | "middle" | "right" }>`
  flex: 1 1 auto;
  text-align: ${(properties) => (properties.align !== undefined ? properties.align : "left")};
`;

export const ModalFormInput = styled("input")<{ width?: string }>`
  flex: 0 0 auto;
  width: ${(properties) => (properties.width ? properties.width : "auto")};
  background-color: ${(properties) => properties.theme.colors.grey1};
  border: 2px solid ${(properties) => properties.theme.colors.grey4};
  border-radius: 0.3rem;
  padding: 0.3rem 1rem;
`;

export const ModalFormTextarea = styled("textarea")<{ height?: string; width?: string }>`
  flex: 0 0 auto;
  height: ${(properties) => (properties.height ? properties.height : "auto")};
  width: ${(properties) => (properties.width ? properties.width : "auto")};
  background-color: ${(properties) => properties.theme.colors.grey1};
  border: 2px solid ${(properties) => properties.theme.colors.grey4};
  border-radius: 0.3rem;
  padding: 0.3rem 1rem;
  resize: none;
`;

export type ModalFormButtonVariants =
  | "default"
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning";

export const ModalFormButton = styled("button")<{
  variant?: ModalFormButtonVariants;
  width?: string;
}>((properties) => {
  let bgColor: string, textColor: string;
  switch (properties.variant) {
    case "primary":
      bgColor = properties.theme.colors.secondary;
      textColor = properties.theme.colors.grey1;
      break;
    case "secondary":
      bgColor = properties.theme.colors.grey3;
      textColor = properties.theme.colors.grey4;
      break;
    case "warning":
      bgColor = "#ffc107";
      textColor = properties.theme.colors.black;
      break;
    case "danger":
      bgColor = "#dc3545";
      textColor = properties.theme.colors.grey1;
      break;
    default:
      bgColor = properties.theme.colors.grey2;
      textColor = properties.theme.colors.grey4;
      break;
  }

  return `
      background-color: ${bgColor};
      border-width: 0;
      border-radius: 0.3rem;
      flex: 0 0 auto;
      align-items: center;
      height: 2rem;
      padding: 0.2rem;
      width: ${properties.width ? properties.width : "auto"};
      cursor: pointer;
      color: ${textColor};

      &:hover {
        filter: brightness(120%);
      }

      & > svg {
        height: 0.8rem;
        width: 0.8rem;
        fill: ${textColor};
      }
    `;
});
