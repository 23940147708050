import { makeFullFragment } from "../utils";
import log from "../en-gb/log";

export default makeFullFragment<typeof log>({
    confirm: {
        description: "Choisissez votre nouveau mot de passe pour activer votre compte.",
        title: "Validez votre compte.",
        error_token: `Il semble que le lien d'activation que vous avez suivi soit invalide.<br/>
            Si vous avez déjà activé votre compte et oublié votre mot de passe, vous
            pouvez le changer en retournant à la page d'accueil et cliquer sur "Mot
            de passe oublié ?".<br/>
            Sinon, la personne ayant créé votre compte l'a peut-être supprimé avant même
            que vous ayez pu le valider. Nous vous conseillons de prendre contact
            directement avec le responsable de votre entreprise.`,
        error_server: "Erreur serveur : impossible de valider votre compte pour l'instant",
        submit: "Valider",
        success: `Votre compte a bien été activé. Vous pouvez maintenant
            retourner à l'accueil du site pour vous connecter à l'aide de votre
            nouveau mot de passe.`,
    },
    edit: {
        description: "Choisissez votre nouveau mot de passe.",
        title: "Nouveau mot de passe",
        error_token: `Il semble que le lien de réinitialisation de mot de
            passe soit invalide.<br/>
            Si vous avez copié ce lien à la main, vérifiez que vous avez bien
            copié l'intégralité du lien.<br/>
            Ce lien a peut-être expiré car il a été demandé il y a plus de 2 heures
            ou parce qu'il a déjà été utilisé pour changer votre mot de passe.<br/>
            Vous pouvez obtenir un nouveau lien de modification de mot de passe en
            retournant à l'accueil du site et en cliquant sur "Mot de passe oublié ?".`,
        error_token_title: "Erreur : lien invalide",
        error_server: "Erreur serveur: impossible de réinitialiser votre mot de passe",
        password_label: "Nouveau mot de passe",
        password_placeholder: "Entrez votre nouveau mot de passe",
        password_confirm_label: "Vérification du nouveau mot de passe",
        password_confirm_placeholder: "Entrez le même mot de passe",
        submit: "Changer le mot de passe",
        success: `Votre mot de passe à bien été changé. Vous pouvez maintenant
            retourner à l'accueil du site pour vous connecter à l'aide de votre
            nouveau mot de passe.`,
        home: "Retourner à l'accueil",
    },
    in: {
        banner_alt: "Entropy - Managing complexity",
        email: "Adresse email",
        password: "Mot de passe",
        login: "Connexion",
        forgot_password: "Mot de passe oublié ?",
        no_account: "Pas encore de compte ?",
        no_account_contact: "Contactez nous !",
        wrong_credentials: "Mauvais identifiant ou mot de passe",
    },
    out: {
        title: "Déconnexion",
        description: "Vous avez été déconnecté(e).",
        back: "Aller à l'accueil",
    },
    reset: {
        title: "Mot de passe oublié",
        description: `Un email contenant un lien pour modifier votre mot de passe sera
        envoyé à l'adresse que vous spécifiez. Il peut prendre jusqu'à une minute pour
        arriver dans votre boîte, et peut potentiellement se trouver dans l'onglet Spam.`,
        email_field: "Adresse email",
        email_placeholder: "exemple@domaine.com",
        email_sent: `Un email a été envoyé à l'adresse {address}, et devrait arriver dans votre
            boîte mail dans moins d'une minute.`,
        submit: "Envoyer",
        back_to_login: "Revenir à la page de connexion",
        unknown_email: "Adresse email invalide",
    },
});
