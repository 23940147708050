import { makeDefaultFragment, required } from "../utils";

const error = makeDefaultFragment({
    assistance: [
        `If you need assistance, you can contact Entropy support at {link}.`,
        {
            link: required<React.ReactNode>(),
        },
    ],
    back_to_page_button: "Refresh current page",
    back_to_previous_page_button: "Go back to previous page",
    cancel_modify_button: "Cancel",
    cannot_modify: "You cannot modify your feedback 5 minutes after the initial report.",
    cannot_send: `You cannot send feedback 30 minutes after the error was reported.`,
    comment_label: "What happened?",
    comment_placeholder: "I clicked on 'X' and then hit 'Confirm'.",
    email_label: "Your email (optional)",
    description: `The Entropy dev team has been notified. If you'd like to help,
    you can describe what happened in the form below.`,
    modify_button: "Modify crash report",
    name_label: "Your name (optional)",
    title: "The application crashed due to an unknown error",
    sent_text: `Your feedback has been sent to the dev team. We will take action
    to fix this bug as soon as possible.`,
    submit_button: "Submit crash report",
    submit_modified_button: "Submit modified report",
});

const not_found = makeDefaultFragment({
    dashboard: "Go back to home page",
    message: [
        "Cannot find requested URL. If you think this is a mistake, please {link}.",
        {
            link: required<React.ReactNode>(),
        },
    ],
    message_link: "contact entropy support",
    title: "Error 404: page not found",
});

export default {
    error,
    not_found,
};
