import React, { useState, useCallback, useEffect } from "react";

import { useStore } from "reto";
import wretch from "wretch";

import UserStore from "../stores/user";
import IntlStore from "../intl";
import { NavigateFunction } from "react-router";

const sentryWretch = wretch("https://sentry.io/api/0/projects/");

export type ErrorFallbackProperties = {
  eventId: string | null;
  navigate: NavigateFunction;
  resetError: () => void;
};

export default function ErrorFallback(properties: ErrorFallbackProperties) {
  const user = useStore(UserStore);
  const { intl } = useStore(IntlStore);

  const [commentSent, setCommentSent] = useState(false);
  const [modifyComment, setModifyComment] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const [cannotSend, setCannotSend] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
    }
  }, [user, setName, setEmail]);

  const sendComment = useCallback(() => {
    if (properties.eventId) {
      sentryWretch
        .url(
          `${process.env.REACT_APP_SENTRY_ORG}/${process.env.REACT_APP_SENTRY_PROJECT}/user-feedback/`
        )
        .headers({
          Authorization: ("Bearer " + process.env.REACT_APP_SENTRY_AUTH_COMMENT) as string,
        })
        .post({
          event_id: properties.eventId,
          name: name,
          email: email,
          comments: comment,
        })
        .error(409, () => {
          setCannotSend(true);
        })
        .res(() => {
          setModifyComment(false);
          setCommentSent(true);
        });
    }
  }, [comment, properties.eventId, name, email]);

  return (
    <div>
      <h1>{intl.error.title}</h1>
      <h2>{intl.error.description}</h2>
      <br />
      {!user && (
        <>
          <label htmlFor="name-input">{intl.error.name_label}</label>
          <br />
          <input
            type="text"
            name="name-input"
            id="name-input"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <br />
          <label htmlFor="email-input">{intl.error.email_label}</label>
          <br />
          <input
            type="text"
            name="email-input"
            id="email-input"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <br />
        </>
      )}
      {(!commentSent || modifyComment) && (
        <>
          <label htmlFor="comment-input">{intl.error.comment_label}</label>
          <br />
          <textarea
            name="comment-input"
            id="comment-input"
            placeholder={intl.error.comment_placeholder}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          />
          <br />
          <button onClick={sendComment} disabled={cannotSend}>
            {commentSent ? intl.error.submit_modified_button : intl.error.submit_button}
          </button>
          {commentSent && (
            <button
              onClick={() => {
                setModifyComment(false);
              }}
            >
              {intl.error.cancel_modify_button}
            </button>
          )}
        </>
      )}
      {commentSent && !modifyComment && (
        <button
          onClick={() => {
            setModifyComment(true);
          }}
        >
          {intl.error.modify_button}
        </button>
      )}
      <br />
      {cannotSend && <span>{commentSent ? intl.error.cannot_modify : intl.error.cannot_send}</span>}
      <br />
      <button
        onClick={() => {
          properties.resetError();
        }}
      >
        {intl.error.back_to_page_button}
      </button>
      <br />
      <button
        onClick={() => {
          properties.navigate(-1);
          properties.resetError();
        }}
      >
        {intl.error.back_to_previous_page_button}
      </button>
      <br />
      <span>
        {intl.error.assistance({
          link: <a href="mailto:support@entropy.sc">support@entropy.sc</a>,
        })}
      </span>
    </div>
  );
}
