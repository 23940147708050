import React, { useState, useRef, useCallback } from "react";

import { ModalButton } from "./styles";
import useDefaultModal from "./default-modal";
import { BaseModalOptions } from "./base-modal";
import { useStore } from "reto";
import IntlStore from "../../intl";

export type ConfirmModalOptions = {
  /** Text inside the cancel button (defaults to Cancel). Set to null to forbid canceling. */
  cancelText: string | null;
  /** Text inside the confirm button (defaults to Confirm). Set to null to forbid confirming. */
  confirmText: string | null;
  /** Message shown inside the confirm modal */
  content: React.ReactNode | (() => React.ReactNode);
} & Pick<BaseModalOptions, "closeOnEscapeKey" | "closeOnOverlayClick">;

export type ConfirmModal = {
  /**
   * Show the modal, wait for the user to confirm or cancel and return a boolean based on its
   * answer. Options parameters can be used to override temporarly default options.
   */
  wait: (options?: Partial<ConfirmModalOptions>) => Promise<boolean>;
};

/**
 * Shows a very simple confirm/cancel modal. Options can be set later when using the wait function.
 */
export default function useConfirmModal(
  defaultOptions?: Partial<ConfirmModalOptions>
): ConfirmModal {
  const { intl } = useStore(IntlStore);

  const [temporaryOptions, setTemporaryOptions] = useState<
    Partial<ConfirmModalOptions> | undefined
  >();
  const confirm = useRef<() => void | undefined>();
  const cancel = useRef<() => void | undefined>();

  const options = {
    cancelText: intl.utils.generic.cancel,
    closeOnEscapeKey: false,
    closeOnOverlayClick: false,
    confirmText: intl.utils.generic.confirm,
    content: "",
    ...defaultOptions,
    ...temporaryOptions,
  } as ConfirmModalOptions;

  const { open, close } = useDefaultModal({
    buttons: (
      <>
        {options.confirmText !== null && (
          <ModalButton onClick={confirm.current} type="button">
            {options.confirmText}
          </ModalButton>
        )}
        {options.cancelText !== null && (
          <ModalButton onClick={cancel.current} type="button" variant="cancel">
            {options.cancelText}
          </ModalButton>
        )}
      </>
    ),
    content: options.content,
    closeOnButton: false,
    closeOnOverlayClick: options.closeOnOverlayClick,
    closeOnEscapeKey: options.closeOnEscapeKey,
    isAnimated: true,
    isCentered: true,
    width: "small",
  });

  const wait = useCallback(
    async (options?: Partial<ConfirmModalOptions>) => {
      if (options) {
        setTemporaryOptions(options);
      }
      open();
      const promise = await new Promise<boolean>((resolve) => {
        confirm.current = () => {
          resolve(true);
        };
        cancel.current = () => {
          resolve(false);
        };
      });
      confirm.current = undefined;
      cancel.current = undefined;
      close();
      if (options) {
        setTemporaryOptions(undefined);
      }
      return promise;
    },
    [close, open]
  );

  return { wait };
}
