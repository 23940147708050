import { useState } from "react";
import { DefaultTheme } from "styled-components";

import { useLocalStorageState } from "ahooks";

import { useMapOption } from "../hooks/utilities/map-option";
import { lightTheme, darkTheme } from "../variables/themes";
import gbIcon from "../assets/svg/country_flags/gb.svg";
import frIcon from "../assets/svg/country_flags/fr.svg";

const fontSizeLocalStorage = "fontSize";
const languageLocalStorage = "language";
const defaultLanguage = "fr-FR";
const defaultAppTheme = "light";
const defaultFontSize = 16;
const defaultShowTooltip = true;
const defaultShowPublicTransports = true;

export type LanguageKey = "en-GB" | "fr-FR";
export type Language = {
  label: string;
  icon: string;
};
const languageList: { [key in LanguageKey]: Language } = {
  "en-GB": {
    label: "English (UK)",
    icon: gbIcon,
  },
  "fr-FR": {
    label: "Français (France)",
    icon: frIcon,
  },
};

export default function ApplicationStore() {
  const [language, setLanguage] = useLocalStorageState<LanguageKey>(languageLocalStorage, {
    defaultValue: defaultLanguage,
  });

  const appTheme = useMapOption(
    new Map<string, DefaultTheme>([
      ["light", lightTheme],
      ["dark", darkTheme],
    ]),
    defaultAppTheme
  );

  const [fontSize, setFontSize] = useLocalStorageState<number>(fontSizeLocalStorage, {
    defaultValue: defaultFontSize,
  });

  const [showTooltip, setShowTooltip] = useState(defaultShowTooltip);
  const [showPublicTransports, setShowPublicTransports] = useState(defaultShowPublicTransports);
  const [unit, setUnit] = useState<"volume" | "percentage">("volume");

  return {
    language,
    setLanguage,
    languageList,
    appTheme: appTheme.selectedVal,
    appThemeName: appTheme.selectedKey,
    setAppTheme: appTheme.setSelected,
    appThemeList: appTheme.map,
    fontSize: fontSize !== undefined ? fontSize : defaultFontSize,
    setFontSize,
    showTooltip,
    setShowTooltip,
    showPublicTransports,
    setShowPublicTransports,
    unit,
    setUnit,
  };
}
