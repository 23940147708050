import React, { useMemo, useState, useCallback } from "react";
import styled from "styled-components";

import { useStore } from "reto";
import { useClickAway } from "ahooks";

import ApplicationStore, { LanguageKey } from "../../stores/application";
import useDomReference from "../../hooks/utilities/dom-reference";

const Icon = styled("img")`
  box-shadow: 0 0 0.3rem;
  height: 2rem;
  cursor: pointer;
  position: relative;
`;

const List = styled("ul")`
  position: absolute;
  top: 2.5rem;
  list-style: none;
  width: 15rem;
  padding: 0;
  margin: 0;

  & > li:first-child {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 0.4rem;
  }

  & > li:last-child {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    padding-bottom: 0.4rem;
  }
`;

const Entry = styled("li")<{ selected: boolean }>`
  list-style: none;
  background-color: ${(properties) => properties.theme.colors.grey2};
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  margin: 0rem;
  display: flex;
  align-items: center;
  color: ${(properties) => properties.theme.colors.tertiary};
  cursor: pointer;
  ${(properties) => properties.selected && "font-weight: bold;"}

  &:hover {
    background-color: ${(properties) => properties.theme.colors.grey3};
  }
`;

const SmallIcon = styled("img")`
  height: 1.6rem;
  padding-right: 0.3rem;
`;

export default function LanguageSelect() {
  const { language, setLanguage, languageList } = useStore(ApplicationStore);

  const currentLanguage = useMemo(() => {
    return language ? languageList[language] : languageList["en-GB"];
  }, [language, languageList]);

  const [isOpen, setIsOpen] = useState(false);

  const containerReference = useDomReference<HTMLDivElement>();

  const onOutsideClick = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen]);

  useClickAway(onOutsideClick, containerReference.get);

  const languageOptions = useMemo(() => {
    const result: React.ReactNode[] = [];
    for (const [key, value] of Object.entries(languageList)) {
      result.push(
        <Entry
          selected={key === language}
          onClick={() => {
            setLanguage(key as LanguageKey);
            setIsOpen(false);
          }}
          key={key}
        >
          <SmallIcon src={value.icon} />
          {value.label}
        </Entry>
      );
    }
    return result;
  }, [language, languageList, setLanguage]);

  return currentLanguage ? (
    <div ref={containerReference.set}>
      <Icon src={currentLanguage.icon} onClick={() => setIsOpen(!isOpen)} />
      {isOpen && <List>{languageOptions}</List>}
    </div>
  ) : null;
}
