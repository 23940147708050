import { toString } from ".";

export function timeLabel(value: number, coef = 1) {
  const hours = Math.floor((value * coef) / 2);
  const minutes = ((value * coef) % 2) * 30;
  return toString(hours, 10) + "h" + toString(minutes, 10);
}

export function timeIndex(value: string, coef = 1) {
  const times = value.split("h");
  const hours = Number(times[0]);
  const minutes = Number(times[1]);

  return !Number.isNaN(minutes)
    ? Math.floor((hours * 60 + minutes) / (30 * coef)) % Math.floor(48 / coef)
    : Math.floor((hours * 60) / (30 * coef)) % Math.floor(48 / coef);
}

export function timeLabelEnd(value: number, coef = 1) {
  const hours = Math.min(Math.floor((value * coef) / 2), 23);
  let minutes = 29;
  if (Math.min(value * coef, 47) % 2 === 1) {
    minutes = 59;
  }
  if (coef === 2) {
    minutes = 59;
  }
  return toString(hours, 10) + "h" + toString(minutes, 10);
}

export function getRanges(timeSelection: SelectionTimeType) {
  const range: [number, number][] = [];

  if (timeSelection.length > 0) {
    if (timeSelection.every((timeValue) => timeValue)) {
      return [[0, timeSelection.length - 1]] as [number, number][];
    }
    let previousTimeIndex: number | null = null;
    for (const [timeIndex, time] of timeSelection.entries()) {
      if (previousTimeIndex !== null && previousTimeIndex !== 0 && !time) {
        range.push([
          previousTimeIndex,
          (timeIndex + timeSelection.length - 1) % timeSelection.length,
        ]);
        previousTimeIndex = null;
      } else if (previousTimeIndex === null && time) {
        previousTimeIndex = timeIndex;
      } else if (previousTimeIndex === 0 && !time) {
        previousTimeIndex = null;
      }
    }
    for (const [timeIndex, time] of timeSelection.entries()) {
      if (previousTimeIndex !== null && !time) {
        range.push([
          previousTimeIndex,
          (timeIndex + timeSelection.length - 1) % timeSelection.length,
        ]);
        break;
      } else if (previousTimeIndex === null && time) {
        previousTimeIndex = timeIndex;
      } else if (!time) {
        break;
      }
    }
  }
  return range;
}

export function count(timeSelection: SelectionTimeType) {
  let count = 0;
  if (timeSelection.length > 0) {
    for (const time of timeSelection.values()) {
      if (time) {
        count += 1;
      }
    }
  }
  return count;
}

export function translation(timeSelection: SelectionTimeType, steps: number) {
  return Array.from(
    { length: timeSelection.length },
    (timeValue, timeIndex) => timeSelection[(timeIndex + steps + 48) % timeSelection.length]
  );
}

export function firstTime(timeSelection: SelectionTimeType) {
  if (timeSelection.length > 0) {
    for (const [timeIndex, time] of timeSelection.entries()) {
      if (time) {
        return timeIndex;
      }
    }
  }
  return 0;
}

export function lastTime(timeSelection: SelectionTimeType) {
  let last = 0;
  if (timeSelection.length > 0) {
    for (const [timeIndex, time] of timeSelection.entries()) {
      if (time) {
        last = timeIndex;
      }
    }
  }
  return last;
}

export function makeTimeSelectionRange(timeSelection: SelectionTimeType) {
  if (timeSelection.length > 0) {
    const ranges = [];
    let currentRange = [];
    let status = false;
    for (const [timeIndex, time] of timeSelection.entries()) {
      if (time && !status) {
        status = true;
        currentRange.push(timeIndex);
      } else if (!time && status) {
        status = false;
        currentRange.push(timeIndex - 1);
        ranges.push(currentRange);
        currentRange = [];
      }
    }
    if (currentRange.length === 1) {
      currentRange.push(timeSelection.length - 1);
      ranges.push(currentRange);
    }
    return ranges;
  }
  return [];
}

const timeRegExp = /^(\d{0,2})[:Hh]?(\d{0,2})$/;
const multiTimeRegExp = /(\d{0,2})[:Hh]?(\d{0,2})/g;

function normalizeTimeMatch(
  hour: string | null,
  minute: string | null,
  defaultHour: number,
  defaultMinute: number
) {
  let resultHour = defaultHour,
    resultMinute: number = defaultMinute;
  if (hour !== null) {
    resultHour = Number.parseInt(hour);
    if (resultHour > 23) resultHour = 23;
  }
  if (minute !== null) {
    resultMinute = Number.parseInt(minute);
    if (resultMinute > 59) resultMinute = 59;
  }
  return (
    (resultHour < 10 ? "0" + resultHour : resultHour) +
    "h" +
    (resultMinute < 10 ? "0" + resultMinute : resultMinute)
  );
}

export function timeFromInput(value: string, defaultHour = 0, defaultMinute = 0) {
  const match = value.match(timeRegExp);
  return normalizeTimeMatch(
    match !== null && match.length >= 2 && match[1] !== "" ? match[1] : null,
    match !== null && match.length >= 3 && match[2] !== "" ? match[2] : null,
    defaultHour,
    defaultMinute
  );
}

export function multipleTimesFromInput(value: string, defaultHour = 0, defaultMinute = 0) {
  const matchAll = value.matchAll(multiTimeRegExp);
  const results: string[] = [];
  for (const match of matchAll) {
    if (match.length >= 2 && match[0] !== "") {
      results.push(
        normalizeTimeMatch(
          match !== null && match.length >= 2 && match[1] !== "" ? match[1] : null,
          match !== null && match.length >= 3 && match[2] !== "" ? match[2] : null,
          defaultHour,
          defaultMinute
        )
      );
    }
  }
  return results;
}

/**
 * Returns whether Time A is greater than Time B.
 * @param timeA Time A (format: HHhMM)
 * @param timeB Time B (format: HHhMM)
 */
export function compareTime(timeA: string, timeB: string) {
  const timeAMatch = timeA.match(timeRegExp);
  const timeBMatch = timeB.match(timeRegExp);
  return timeAMatch !== null && timeBMatch !== null
    ? (Number.parseInt(timeAMatch[1]) - Number.parseInt(timeBMatch[1])) * 60 +
        Number.parseInt(timeAMatch[2]) -
        Number.parseInt(timeBMatch[2])
    : 0;
}
