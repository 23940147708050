import { useState, useCallback } from "react";

interface MapOption<K, V> {
  selectedKey: K;
  selectedVal: V;
  map: Map<K, V>;
  setSelected: (key: K) => void;
}

export function useMapOption<K, V>(map: Map<K, V>, initialKey: K): MapOption<K, V> {
  const [selectedKey, setSelectedKey] = useState(initialKey);

  const [selectedValue, setSelectedValue] = useState(() => {
    const value = map.get(initialKey);
    if (value === undefined) {
      throw new Error("Cannot find value " + initialKey);
    }
    return value;
  });

  const setSelected = useCallback(
    (key: K) => {
      const value = map.get(key);
      if (value === undefined) {
        throw new Error("Cannot find value" + key);
      }
      setSelectedKey(key);
      setSelectedValue(value);
    },
    [map]
  );

  return {
    selectedKey,
    selectedVal: selectedValue,
    map,
    setSelected,
  };
}
