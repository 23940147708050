import { useEffect } from "react";

import { setTag, setUser } from "@sentry/react";
import { useStore } from "reto";

import { useQuery } from "../remotes";
import AuthenticateStore from "./authenticate";
import useFlowdysseaQueries from "./../remotes/flowdyssea";

export default function UserStore() {
  const { isAccessible, userRandomKey } = useStore(AuthenticateStore);
  const { usersQ } = useFlowdysseaQueries();

  const { data: userId } = useQuery(
    "my_uuid",
    isAccessible() && { query: { key: userRandomKey } },
    usersQ.ping,
    {
      staleTime: 10_000,
      cacheTime: 10_000,
      refetchOnWindowFocus: true,
    }
  );

  const { data: user } = useQuery(
    "my_user",
    userId !== undefined && { parameters: { user: userId.value } },
    usersQ.get,
    {
      staleTime: 10_000,
      cacheTime: 10_000,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (user) {
      setTag("organisation", "");
      setUser({
        id: user.id,
        email: user.email,
        username: user.name,
      });
    } else {
      setTag("organisation", "");
      setUser(null);
    }
  }, [user]);

  return user;
}
