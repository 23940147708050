import { makeDefaultFragment, required } from "../utils";

export default makeDefaultFragment({
    confirm: {
        description: "Choose your new password to activate your account.",
        title: "Confirm your account.",
        error_token: `It appears the account validation link you followed is invalid.<br/>
            Perhaps you already used it to create your account and forgot your
            password? If that is the case, go back to the home page and click
            "Forgot password".<br/>
            Otherwise maybe the person who created your account deleted it before you
            could even activate it. You can contact the manager of your company for
            more informations.`,
        error_server: "Server error: cannot validate your account right now",
        submit: "Confirm",
        success: `Your account has been activated. You can now return to the home page
            to log in using your new password.`,
    },
    edit: {
        description: "Choose your new password.",
        title: "New password",
        error_token: `It appears the reset link you followed is invalid.<br/>
            If you copied this link manually, make sure you copied all of it.<br/>
            This link may have expired because it was requested more than 2 hours
            ago or because it was already used to change your password.<br/>
            You can obtain a new password reset link by going back to the home
            page and clicking "Forgot password?".`,
        error_token_title: "Error: invalid link",
        error_server: "Server error: cannot reset your password right now",
        password_label: "New password",
        password_placeholder: "Enter your new password",
        password_confirm_label: "Confirm your new password",
        password_confirm_placeholder: "Enter your new password again",
        submit: "Change password",
        success: `Your password has been changed. You can now return to the home page
            to log in using your new password.`,
        home: "Back to home screen",
    },
    in: {
        banner_alt: "Entropy - Managing complexity",
        email: "Email address",
        password: "Password",
        login: "Login",
        forgot_password: "Forgot password?",
        no_account: "No account yet?",
        no_account_contact: "Contact us!",
        wrong_credentials: "Wrong credentials",
    },
    out: {
        title: "Diconnected",
        description: "You have been logged out.",
        back: "Go back to login screen",
    },
    reset: {
        title: "Forgot password",
        description: `An email containing a link to pick a new password will be sent
        to the address you enter. It can take up to a minute to arrive, and may be
        classified as a spam.`,
        email_field: "Email address",
        email_placeholder: "example@domain.com",
        email_sent: [
            `An email has been sent to {address}, and should arrive in your inbox in
            less than a minute.`,
            {
                address: required<React.ReactNode>(),
            },
        ],
        submit: "Send",
        back_to_login: "Back to login page",
        unknown_email: "Invalid email adress",
    },
});
