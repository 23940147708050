import React, { useCallback, useMemo } from "react";
import styled, { keyframes } from "styled-components";

import useBaseModal, { BaseModalOptions, ModalProperties } from "./base-modal";

import { ModalCallback, callModalCallback } from "./utils";

export type DefaultModalWidth = "small" | "large";

/** Modal opening animation */
export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

/** Div taking the whole page, used to center and determine the modal's z-index */
const ModalWrapper = styled.div<{ isCentered: boolean; orderId: number }>`
  pointer-events: none;
  display: inline-table;
  position: fixed;
  padding: 0.5rem;
  top: ${(properties) => (properties.isCentered ? "top: 40% !important" : 0)};
  left: 0;
  z-index: ${(properties) => properties.orderId * 2};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  @media (min-width: 1000px) {
    top: 0 !important;
    display: ${(properties) => (properties.isCentered ? "flex" : "inline-table")};
    align-items: center;
  }
`;

/** Modal root displayed div */
export const ModalMain = styled("div")<{ width: DefaultModalWidth; isAnimated: boolean }>`
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  pointer-events: auto;
  background-color: ${(properties) => properties.theme.colors.grey1};
  border: solid 0.2rem ${(properties) => properties.theme.colors.tertiary};
  color: ${(properties) => properties.theme.colors.black};
  box-shadow: 0 4px 10px 4px rgba(${(properties) => properties.theme.colors.tertiary}, 0.3);
  min-width: 100%;
  max-width: ${(properties) => (properties.width === "large" ? "40rem" : "30rem")};
  padding: 0 1rem;

  @media (min-width: 500px) {
    min-width: ${(properties) => (properties.width === "large" ? "40rem" : "30rem")};
  }

  animation-duration: ${(properties) => (properties.isAnimated ? "0.5s" : "0")};
  animation-fill-mode: ${(properties) => (properties.isAnimated ? "both" : "none")};
  animation-name: ${(properties) => (properties.isAnimated ? fadeIn : "none")};
`;

/** Modal header containg the title */
export const ModalHeader = styled("div")`
  display: flex;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
`;

/** Modal title */
export const ModalTitle = styled("div")`
  font-weight: 700;
  font-size: 1.2rem;
`;

/** Optional top-right button to close the modal */
export const ModalCloseButton = styled("button")`
  padding: 1rem;
  background: ${(properties) => properties.theme.colors.grey1};
  margin: -1rem -1rem -1rem auto;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: ${(properties) => properties.theme.colors.tertiary};
  opacity: 0.3;
  cursor: pointer;
  border: none;
  outline: 0 !important;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;

/** Div containing the user defined content */
export const ModalContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(properties) => properties.theme.colors.grey1};
  border-radius: 0.3rem;
  padding: 0.5rem 1.5rem;
`;

/** Modal footer containing extra buttons */
export const ModalFooter = styled("div")`
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
`;

type DefaultModalOptions = {
  /** List of button to show bottom right under the content, from left to right */
  buttons: React.ReactNode;
  /**  */
  content: React.ReactNode;
  /** Should the modal have a close button on the top right? */
  closeOnButton: boolean;
  /** Callback called when the user clicks the close button. The modal won't be closed if the
   * callback returns false.
   */
  onButtonClose?: ModalCallback;
  /** Should the modal's opening be animated? */
  isAnimated: boolean;
  /** Should the modal be centered on screen? */
  isCentered: boolean;
  /** Header title of the modal, on the same line than the close button if present */
  title?: string;
  /** Width (large or small) of the modal */
  width: DefaultModalWidth;
} & BaseModalOptions;

/** Default Modal renderer */
export function DefaultModal(properties: ModalProperties<DefaultModalOptions>) {
  const { close, id, options } = properties;
  const { buttons, closeOnButton, content, onButtonClose, isAnimated, isCentered, title, width } =
    options;

  const onCloseButtonClick = useCallback(async () => {
    if (closeOnButton && (!onButtonClose || (await callModalCallback(onButtonClose)))) {
      close();
    }
  }, [close, closeOnButton, onButtonClose]);

  return (
    <ModalWrapper orderId={id} isCentered={isCentered}>
      <ModalMain isAnimated={isAnimated} width={width}>
        {(title !== undefined || closeOnButton) && (
          <ModalHeader>
            {title !== undefined && <ModalTitle>{title}</ModalTitle>}
            {closeOnButton && (
              <ModalCloseButton
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCloseButtonClick}
              >
                <span aria-hidden="true">&times;</span>
              </ModalCloseButton>
            )}
          </ModalHeader>
        )}
        <ModalContent>{content}</ModalContent>
        <ModalFooter>
          {buttons &&
            (Array.isArray(buttons)
              ? buttons.map((button, index) => {
                  return <React.Fragment key={index}>{button}</React.Fragment>;
                })
              : buttons)}
        </ModalFooter>
      </ModalMain>
    </ModalWrapper>
  );
}

/**
 * This is the most configurable modal which can fit any use case. For simpler modals you can use
 * useConfirmModal or useErrorModal instead.
 *
 * TO-DO: implement new modal style made by Romy for bookmarks.
 */
export default function useDefaultModal(options: Partial<DefaultModalOptions>) {
  const defaultedOptions = useMemo(
    () =>
      ({
        ...options,
        canCancelLoading: false,
        closeOnButton: true,
        closeOnEscapeKey: true,
        closeOnOverlayClick: false,
        isAnimated: true,
        isCentered: true,
        width: "large",
      } as DefaultModalOptions),
    [options]
  );

  return useBaseModal(DefaultModal, defaultedOptions);
}
