import React, { Suspense, lazy } from "react";
import styled from "styled-components";

import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider, useStore } from "reto";

import AuthenticateStore from "./../stores/authenticate";
import BookmarkStore from "./../stores/bookmarks";
import ProjectStore from "./../stores/project";
import SelectionStore from "./../stores/selection";
import GlobalStyle from "./../variables/html";
import useDomReference from "../hooks/utilities/dom-reference";
import { ModalsProvider } from "../components/modal";
import OptionsStore from "../stores/options";
import NotFoundPage from "../scenes/404";
import Debug from "../scenes/debug";
import { NavitiaStore } from "../remotes/navitia";
import ApplicationStore from "../stores/application";

const Fluidity = lazy(() => import("./../scenes/fluidity"));
const Dashboard = lazy(() => import("./../scenes/dashboard"));
const Preview = lazy(() => import("./../scenes/preview"));
const PreviewEdit = lazy(() => import("./../scenes/preview/edit"));
const PreviewNew = lazy(() => import("./../scenes/preview/new"));
const OrganisationDetails = lazy(() => import("../scenes/dashboard/organisation-details"));
const Administration = lazy(() => import("./../scenes/admin"));
const LogIn = lazy(() => import("./../scenes/log/in"));
const LogOut = lazy(() => import("./../scenes/log/out"));
const LogReset = lazy(() => import("./../scenes/log/reset"));
const LogEdit = lazy(() => import("./../scenes/log/edit"));

const ModalsDiv = styled("div")`
  height: 100%;
  width: 100%;
`;

function PrivateRoute({ children }: { children: React.ReactElement }) {
  const { isRefreshable } = useStore(AuthenticateStore);
  return isRefreshable() ? children : <Navigate to="/log/out" />;
}

function AdminPrivateRoute({ children }: { children: React.ReactElement }) {
  const { isRefreshable, isAdministrator } = useStore(AuthenticateStore);
  return isRefreshable() && isAdministrator() ? children : <Navigate to="/dashboard" />;
}

function Routing() {
  const defaultModalDiv = useDomReference<HTMLDivElement>();

  const { fontSize } = useStore(ApplicationStore);

  return (
    <ModalsProvider anchor={defaultModalDiv.get}>
      <ModalsDiv ref={defaultModalDiv.set}>
        <Suspense
          fallback={
            <div className="loader-wrapper">
              <div className="loader">
                <div className="roller"></div>
                <div className="roller"></div>
              </div>

              <div id="loader2" className="loader">
                <div className="roller"></div>
                <div className="roller"></div>
              </div>

              <div id="loader3" className="loader">
                <div className="roller"></div>
                <div className="roller"></div>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="" element={<LogIn />} />
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="project/:projectId"
              element={
                <PrivateRoute>
                  <Provider of={NavitiaStore} memo>
                    <Provider of={OptionsStore} memo>
                      <Provider of={ProjectStore} memo>
                        <Provider of={SelectionStore} memo>
                          <Provider of={BookmarkStore} memo>
                            {process.env.REACT_APP_BRANCH === "master" ? (
                              <Fluidity />
                            ) : (
                              <>
                                <Debug />
                                <Fluidity />
                              </>
                            )}
                          </Provider>
                        </Provider>
                      </Provider>
                    </Provider>
                  </Provider>
                </PrivateRoute>
              }
            />
            <Route
              path="organisation"
              element={
                <PrivateRoute>
                  <OrganisationDetails />
                </PrivateRoute>
              }
            />

            <Route
              path="admin"
              element={
                <AdminPrivateRoute>
                  <Administration />
                </AdminPrivateRoute>
              }
            />
            <Route path="log/in" element={<LogIn />} />
            <Route
              path="preview/new"
              element={
                <AdminPrivateRoute>
                  <PreviewNew />
                </AdminPrivateRoute>
              }
            />
            <Route path="preview/:previewId" element={<Preview />} />
            <Route
              path="preview/:previewId/edit"
              element={
                <AdminPrivateRoute>
                  <PreviewEdit />
                </AdminPrivateRoute>
              }
            />
            <Route path="log/out" element={<LogOut />} />
            <Route path="log/reset" element={<LogReset />} />
            <Route path="log/edit" element={<LogEdit />} />
            <Route path="log/confirm" element={<LogEdit isNewAccount />} />
            <Route element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <GlobalStyle fontSize={fontSize} />
        <ToastContainer />
      </ModalsDiv>
    </ModalsProvider>
  );
}

export default Routing;
