import { makeDefaultDictionary } from "../utils";
import dashboard from "./dashboard";
import fluidity from "./fluidity";
import log from "./log";
import other from "./other";
import utils from "./utils";

export default makeDefaultDictionary({
    language: "en-GB",
    messages: {
        dashboard: dashboard,
        error: other.error,
        fluidity: fluidity,
        log: log,
        not_found: other.not_found,
        utils: utils,
    },
});
