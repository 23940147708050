import { makeFullFragment } from "../utils";
import dashboard from "../en-gb/dashboard";

export default makeFullFragment<typeof dashboard>({
    admin_button: "Administration",
    organisations_button: "Organisation",
    organisation: {
        add_button: "Créer",
        add_button_placeholder: "Créer un nouveau compte",
        add_cancel: "Annuler",
        add_confirm: "Créer",
        add_title: "Créer un nouveau compte",
        delete_button: "Supprimer",
        delete_confirmation: `Êtes vous sûr de vouloir supprimer le compte
        de {name}?. Cette action est irréversible.`,
        edit_button: "Modifier",
        edit_cancel: "Annuler",
        edit_confirm: "Modifier",
        edit_title: "Modifier un compte",
        form_firstname: "Prénom",
        form_firstname_error: "Veuillez entrer un prénom",
        form_lastname: "Nom",
        form_lastname_error: "Veuillez entrer un nom",
        form_email: "Email",
        form_email_error_invalid: "Adresse email invalide",
        form_email_error_exists: "Adresse email déjà utilisée par un autre compte",
        form_role: "Rôle",
        form_role_user: "Utilisateur",
        form_role_manager: "Responsable",
        search_placeholder: "Rechercher",
    },
    projects: {
        add_members: {
            title: "Ajouter des membres au projet",
            placeholder: "Séléctionnez un utilisateur à ajouter ...",
            cancel: "Annuler",
            confirm: "Ajouter",
            cancel_text: "Êtes vous sûr de ne pas vouloir ajouter les membres sélectionnés ?",
            cancel_no: "Non",
            cancel_yes: "Oui",
        },
        back_to_list: "Liste des projets",
        delete_member: {
            message: "Voulez vous vraiment retirer l'accès de {username} à ce projet ?",
            cancel: "Annuler",
            confirm: "Supprimer",
        },
        hello: "Bonjour, {name}",
        member_list: "Membres du projet",
        no_project_message: `Vous n'êtes assigné à aucun projet. Vous pouvez contacter votre
        responsable pour plus d'informations.`,
        no_project_message_manager: `Vous n'êtes assigné à aucun projet. Vous pouvez voir la
        liste des projets pour assigner un projet à vous ou quelqu'un d'autre.`,
        no_all_projects: `Votre organisation n'a aucun projet. Vous pouvez contacter le support
        client d'Entropy à l'adresse support@entropy.sc pour plus d'informations.`,
        no_project_title: "Aucun projet",
        open_project: "Ouvrir le projet",
        view_all_projects: "Voir tous les projets",
        view_my_projects: "Voir uniquement mes projets",
        title: "Mes projets",
        title_all: "Projets de l'organisation",
    },
    logout_button: "Déconnexion",
});
