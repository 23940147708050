import React, { useState, useRef, useCallback } from "react";

import { useStore } from "reto";

import { ModalButton } from "./styles";
import useDefaultModal from "./default-modal";
import IntlStore from "../../intl";

export type ErrorModalOptions = {
  /** Error message */
  content: React.ReactNode | (() => React.ReactNode);
  /** Text to show inside the close button */
  closeText: string;
  /** Error title. Defaults to "Error". */
  title: string;
};

export type ErrorModal = {
  /**
   * Show the modal and waits for the user to close it. Options parameters can be used to override
   * temporarly default options.
   */
  wait: (options?: Partial<ErrorModalOptions>) => Promise<void>;
};

/**
 * Shows a very simple error modal. Options can be set later when using the wait function.
 */
export default function useErrorModal(defaultOptions?: Partial<ErrorModalOptions>): ErrorModal {
  const { intl } = useStore(IntlStore);

  const [temporaryOptions, setTemporaryOptions] = useState<
    Partial<ErrorModalOptions> | undefined
  >();
  const ok = useRef<() => void | undefined>();

  const options = {
    closeText: intl.utils.generic.close,
    content: "",
    title: intl.utils.error.modal_title,
    ...defaultOptions,
    ...temporaryOptions,
  } as ErrorModalOptions;

  const { open, close } = useDefaultModal({
    buttons: (
      <ModalButton onClick={ok.current} type="button">
        {options.closeText}
      </ModalButton>
    ),
    content: options.content,
    closeOnButton: true,
    closeOnOverlayClick: true,
    closeOnEscapeKey: true,
    isAnimated: true,
    isCentered: true,
    title: options.title,
    width: "small",
  });

  const wait = useCallback(
    async (options?: Partial<ErrorModalOptions>) => {
      if (options) {
        setTemporaryOptions(options);
      }
      open();
      const promise = await new Promise<void>((resolve) => {
        ok.current = () => {
          resolve();
        };
      });
      ok.current = undefined;
      close();
      if (options) {
        setTemporaryOptions(undefined);
      }
      return promise;
    },
    [close, open]
  );

  return { wait };
}
