import { Endpoint } from "../query";

/**
 * Returns an endpoint made from another endpoint but with different arguments.
 * @param endpoint Original endpoint.
 * @param argumentsFunction Argument transformation function.
 *
 * ### Example
 * We have an endpoint which take a firstname and lastname as arguments.
 * We want to make a new endpoint which takes a fullname instead.
 * ```ts
 * const newEndpoint = transformArguments(endpoint, (parameters: { fullname: string }) => {
 *   const firstname = parameters.fullname.split(" ")[0];
 *   const lastname = parameters.fullname.split(" ")[1];
 *   return { firstname, lastname };
 * })
 * ```
 */
export function transformArguments<TArguments, TResponse, TWrapper>(
  endpoint: Endpoint<TArguments, TResponse>,
  argumentsFunction: (parameters: TWrapper) => TArguments
) {
  return {
    base: endpoint.base,
    fn: (parameters: TWrapper) => endpoint.fn(argumentsFunction(parameters)),
  } as Endpoint<TWrapper, TResponse>;
}

/**
 * Returns an endpoint which transforms the response of another endpoint.
 * @param endpoint Original endpoint.
 * @param responseFunction Response transformation function.
 *
 * ### Example
 * We have an endpoint which returns someone's age.
 * We want to make a new endpoint which returns whether someone is an adult.
 * ```ts
 * const newEndpoint = transformResponse(endpoint, (response) => {
 *   return { isAnAdult: response.age >= 18 };
 * });
 * ```
 */
export function transformResponse<TArguments, TResponse, TWrapper>(
  endpoint: Endpoint<TArguments, TResponse>,
  responseFunction: (response: Promise<TResponse>) => Promise<TWrapper>
) {
  return {
    base: endpoint.base,
    fn: (parameters: TArguments) => responseFunction(endpoint.fn(parameters)),
  } as Endpoint<TArguments, TWrapper>;
}
