import { Wretcher, ResponseChain, WretcherError } from "wretch";

import { Endpoint } from "../query";

/**
 * In truth, this holds a boolean value (True) if the user defined
 * anything, and undefined otherwise.
 */
export type DataType<T> = T;

/**
 * Declares a type for remote's body or response.
 */
export function type<T>(): DataType<T> {
  return true as unknown as DataType<T>;
}

export type ParametersBaseType = Record<string, string | number> | undefined;
export type QueryBaseType = Record<string, string | number> | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BodyBaseType = any | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ResponseBaseType = any | undefined;

export function format<TParameters extends Record<string, string | number>>(
  instance: Wretcher,
  url: string,
  parameters?: TParameters
) {
  if (parameters === undefined) {
    return instance.url(url);
  } else {
    let finalUrl = url;
    for (const [key, value] of Object.entries(parameters)) {
      finalUrl = finalUrl.replace(
        "<" + key + ">",
        typeof value === "number" ? value.toString() : value
      );
    }
    return instance.url(finalUrl);
  }
}

export function query<TQuery extends QueryBaseType>(instance: Wretcher, query?: TQuery) {
  return query === undefined ? instance : instance.query(query as NonNullable<TQuery>);
}

export function error<TResponse extends ResponseBaseType>(
  instance: Wretcher,
  onError?: (error: WretcherError) => TResponse
) {
  return onError === undefined ? instance : instance.catcher("Error", onError);
}

export function result<TResponse extends ResponseBaseType>(
  instance: ResponseChain & Promise<TResponse>,
  response?: TResponse,
  buffer?: boolean
) {
  return response === undefined
    ? instance.res<void>()
    : buffer
    ? instance.arrayBuffer()
    : instance.json<TResponse>();
}

/** Configuration object to describe an endpoint. */
export type RestEndpointConfig<
  TParameters extends ParametersBaseType = undefined,
  TQuery extends QueryBaseType = undefined,
  TBody extends BodyBaseType = undefined,
  TResponse extends ResponseBaseType = undefined,
  TCanHaveBody extends boolean = false
> = Omit<
  {
    /** Base Wretcher instance. */
    base: Wretcher;
    /** An URL relative to the Wretcher instance base URL. */
    url: string;
    /** Type of parameters to inject in the URL (optional). */
    parameters?: DataType<TParameters>;
    /** Type of query parameters to use in the URL (optional). */
    query?: DataType<TQuery>;
    /** Type of body to send with the request (optional). Only available
     * within POST and PUT requests.
     */
    body?: DataType<TBody>;
    /** Expected type of response from the server (optional). */
    response?: DataType<TResponse>;
    /** Error handler (optional). */
    handleError?: DataType<(error: WretcherError) => TResponse>;
  },
  false extends TCanHaveBody ? "body" : ""
>;

/** Object containing arguments to query against an endpoint. */
export type RestEndpointArguments<
  TParameters extends ParametersBaseType = undefined,
  TQuery extends QueryBaseType = undefined,
  TBody extends BodyBaseType = undefined
> = Omit<
  {
    /** Parameters to inject in the URL. */
    parameters: TParameters;
    /** Query parameters to use in the URL. */
    query: TQuery;
    /** Body to send with the request. */
    body: TBody;
  },
  | (undefined extends TParameters ? "parameters" : "")
  | (undefined extends TQuery ? "query" : "")
  | (undefined extends TBody ? "body" : "")
>;

/** Rest endpoint to use with useQuery, useQueries, useInfiniteQuery and useMutation. */
export type RestEndpoint<
  TParameters extends ParametersBaseType = undefined,
  TQuery extends QueryBaseType = undefined,
  TBody extends BodyBaseType = undefined,
  TResponse extends ResponseBaseType = undefined
> = Endpoint<RestEndpointArguments<TParameters, TQuery, TBody>, TResponse>;
