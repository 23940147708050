import { useState, useCallback } from "react";

/**
 * More reliable React.createRef which updates on every mount/unmount of targeted DOM node.
 */
export default function useDomReference<T>() {
  const [get, setter] = useState<T | null>(null);
  const set = useCallback((reference: T | null) => setter(reference), [setter]);

  return {
    get,
    set,
  };
}
