import { Wretcher } from "wretch";

import { type, describeDelete, describePost, describePut, describeGet } from "../descriptors";

export type Organisation = {
  id?: string;
  name: string;
  domain: string;
  restrictedEmails: boolean;
};

export type RemoteOrganisation = {
  id: string;
  createOn: string;
  updateOn: string;
  name: string;
  domain: string;
  restrictedEmails: boolean;
  countUser: number;
  countProject: number;
};

export const describeFlowdysseaOrganisations = (wretcher: Wretcher) => ({
  create: describePost({
    base: wretcher,
    url: "/organisations/",
    body: type<Organisation>(),
  }),

  fetch: describeGet({
    base: wretcher,
    url: "/organisations/",
    response: type<{ organisations: RemoteOrganisation[] }>(),
  }),

  get: describeGet({
    base: wretcher,
    url: "/organisations/<organisation>",
    parameters: type<{ organisation: string }>(),
    response: type<RemoteOrganisation>(),
  }),

  update: describePut({
    base: wretcher,
    url: "/organisations/<organisation>",
    parameters: type<{ organisation: string }>(),
    body: type<Organisation>(),
  }),

  delete: describeDelete({
    base: wretcher,
    url: "/organisations/<organisation>",
    parameters: type<{ organisation: string }>(),
  }),

  addProject: describePut({
    base: wretcher,
    url: "/organisations/<organisation>/projects/<project>",
    parameters: type<{ organisation: string; project: string }>(),
  }),

  removeProject: describeDelete({
    base: wretcher,
    url: "/organisations/<organisation>/projects/<project>",
    parameters: type<{ organisation: string; project: string }>(),
  }),
});
