import { Wretcher } from "wretch";

import { type, describeDelete, describeGet, describePost, describePut } from "../descriptors";

export interface RemoteBookmark {
  id: string;
  project: string;
  entity: string;
  topic: string;
  granularity: AdministrativeLevelType;
  identifier: string;
  description: string;
  createOn: string;
  updateOn: string;
  originsArea: string[];
  originsCountry: string[];
  destinationsArea: string[];
  destinationsCountry: string[];
  version: [string, string];
}

export interface RemoteBookmarkCreator {
  granularity: AdministrativeLevelType;
  identifier: string;
  description: string;
  originsArea: string[];
  originsCountry: string[];
  destinationsArea: string[];
  destinationsCountry: string[];
  version: [string, string];
}

export interface BookmarkComment {
  id: string;
  user: string;
  username: string;
  content: string;
  topic: string;
  createOn: string;
  updateOn: string;
}

export const describeFlowdysseaBookmarks = (wretcher: Wretcher) => ({
  create: describePost({
    base: wretcher,
    url: "/projects/<project>/bookmarks/",
    parameters: type<{ user: string; project: string }>(),
    body: type<RemoteBookmarkCreator>(),
  }),

  fetch: describeGet({
    base: wretcher,
    url: "/projects/<project>/bookmarks",
    parameters: type<{ project: string }>(),
    response: type<RemoteBookmark[]>(),
  }),

  get: describeGet({
    base: wretcher,
    url: "/projects/<project>/bookmarks/<bookmark>",
    parameters: type<{ project: string; bookmark: string }>(),
    response: type<RemoteBookmark>(),
  }),

  update: describePut({
    base: wretcher,
    url: "/projects/<project>/bookmarks/<bookmark>",
    parameters: type<{ project: string; bookmark: string }>(),
    body: type<RemoteBookmarkCreator>(),
  }),

  delete: describeDelete({
    base: wretcher,
    url: "/projects/bookmarks/<bookmark>",
    parameters: type<{ bookmark: string }>(),
  }),

  copy: describePut({
    base: wretcher,
    url: "/projects/bookmarks/<bookmark>/copy",
    parameters: type<{ bookmark: string }>(),
    body: type<{ makeTopic: boolean }>(),
  }),

  share: describePost({
    base: wretcher,
    url: "/projects/<project>/bookmarks/<bookmark>/share",
    parameters: type<{ project: string; bookmark: string }>(),
    body: type<{ makeCopy: boolean; makeTopic: boolean }>(),
  }),

  fetchShortcuts: describeGet({
    base: wretcher,
    url: "/projects/<project>/bookmarks/shortcuts",
    parameters: type<{ user: string; project: string }>(),
    response: type<{ shortcuts: { bookmark: string; key: number }[] }>(),
  }),

  updateShortcut: describePost({
    base: wretcher,
    url: "/projects/bookmarks/<bookmark>/shortcut",
    parameters: type<{ bookmark: string }>(),
    body: type<{ key?: number }>(),
  }),

  fetchComments: describeGet({
    base: wretcher,
    url: "/projects/topics/<topic>/messages",
    parameters: type<{ topic: string }>(),
    response: type<BookmarkComment[]>(),
  }),

  createComment: describePost({
    base: wretcher,
    url: "/projects/topics/<topic>/messages",
    parameters: type<{ topic: string }>(),
    body: type<{ content: string }>(),
  }),

  updateComment: describePut({
    base: wretcher,
    url: "/projects/topics/<topic>/messages/<message>",
    parameters: type<{ topic: string; message: string }>(),
    body: type<{ content: string }>(),
  }),

  deleteComment: describeDelete({
    base: wretcher,
    url: "/projects/topics/<topic>/messages/<message>",
    parameters: type<{ topic: string; message: string }>(),
  }),
});
